import { calendar_v3 } from "@dbt/commons/api/server/google";
import { buildUrl, getOrThrowResponse } from "@dbt/commons/utils";
export const ApiRoutes = {
  getCalendarEvents: (email: string | null, ceEmail: string | null, fromTime?: string | number) => buildUrl("/api/google/calendar", {
    email,
    ceEmail,
    fromTime
  })
};
export async function getCalendarEvents(email: string | null, ceEmail: string | null, fromTime?: string | number): Promise<{
  meetings: calendar_v3.Schema$Event[];
}> {
  const response = await fetch(ApiRoutes.getCalendarEvents(email, ceEmail, fromTime));
  return getOrThrowResponse(response);
}