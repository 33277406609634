import _styled from "@emotion/styled";
import React, { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { Spinner } from "../icons/other";
import { keyframes } from "@emotion/css";
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export interface LoadingAlarmProps {
  loadingAreaName?: string;
  enableLoadingDelayAlarm?: boolean;
  loadingDelayAlarmTimeout?: number;
}
export interface LoadingProps extends LoadingAlarmProps, React.HTMLAttributes<HTMLDivElement> {
  size?: number | string;
}
const LoadingContainer = _styled.div<LoadingProps>`
  ${({
  size
}) => `
    width: ${size}px;
    height: ${size}px;
  `}
  animation: ${rotate} 1s linear infinite;
`;
export const Loading = (props: LoadingProps) => {
  const {
    loadingAreaName,
    enableLoadingDelayAlarm = true,
    loadingDelayAlarmTimeout = 10 // In Seconds
  } = props;
  useEffect(() => {
    if (enableLoadingDelayAlarm) {
      const timer = setTimeout(() => {
        Sentry.captureException(new Error("Loading spinner being running for long time."), {
          level: "warning",
          tags: {
            "loader.area-name": loadingAreaName,
            "loader.timeout": loadingDelayAlarmTimeout
          }
        });
      }, loadingDelayAlarmTimeout * 1000);
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <LoadingContainer {...props}>
      <Spinner {...props} />
    </LoadingContainer>;
};
const Container = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "padding": "0.5rem"
});
export const LoadingComponent = (props: LoadingProps) => {
  return <Container>
      <Loading {...props} />
    </Container>;
};