import _styled from "@emotion/styled";
const StyledImage = _styled.img`
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
const loaderIconAsBase64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgB3ZVNaBNBFIDnb6MVoemxFxNBK4qQxoMetCYeRAuCLdiDh5IUPAlKCyo9KLvBg0IPaQ8iIqWborXFhjaBgojgFj0UhNpSRKqW3Uj0am6F3XkzToRIZdOYJif9YODNzGO+XXjzBqF/HVxrc25uIti6tzVGsJYgjEYZZWFCKNIYcwjT3qs4F4meyDQkWMhPX2SUmoSSIGOsRCnNYUKdAAtIyliUaVqEUhYilBaU2Dh0OJKpW5DPTqapxgYJIZaSpM5291nV8tbW3sUZYRNKHsKEjR3sODKE/sbc83F9PmuKXDZjoDr5vL5mbHz5KAv2p3TNxJknjxKz049FdmbcQDvEtteNr4UNWSzaiW2TpjIP7JmnD23UIMWi8/r7t8IP27aDlTVSCSYn0kkhedh1+QBqEEplShVAsKVlV9InAC56OIDTP3DNQg3S3r6/XBSFQIDGfAIBfB9wbwU1CcHMIoR1VuasEgBAFCOZR02yew81hKARn0AIri4FlqhJzp3qiEmMdRXm/hAA5466d2HUJKqF9CIp237PK4EHfMXjbg9qEtU+yi3E8gkk8Iz6i+DIvRtx1CDdZw7EVN8Ka4zmfAK06VogwFEiHTUIwdRUX++8eOOYPsGQMVoSXA5yDvG7t6/uWNJz/qiuml6YMpLauk63Tl5ZS+vx08fbpIDhrpOdaPHt8iKqg0sXjukYYwNJPJZ/+eH+1r2q7To1fEXnUhjAwfGEmxxJP6sq6u/rinHuqbqHuModnV1Y9rXrbR+cOzeTSdU6dAAeUsPhHqwCuCtcCAyeF+LA4wIgrEZJHT40Nb9kVjun5pNZ5tb1ywmP817xSwQRJcPlYlAVtwrCtdAmNc15q4T+W34CKbIlWbLhWhEAAAAASUVORK5CYII=";
export function Loader() {
  return <StyledImage src={loaderIconAsBase64} />;
}