export const BankIdIcon = ({
  color = "#193E4F",
  width = 69,
  height = 64
}: {
  color?: string;
  width?: number;
  height?: number;
}) => {
  return <svg width={width} height={height} viewBox="0 0 69 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.0179 33.4083L24.8366 15.5947H21.8166C20.3944 15.5947 18.5867 14.8086 18.0448 13.3479C17.8649 12.8617 17.4601 11.1546 19.7968 9.50544C20.6214 8.92714 21.1719 8.28674 21.2768 7.79412C21.3839 7.28222 21.249 6.83243 20.887 6.4876C20.3687 5.99712 19.3728 5.72725 18.0812 5.72725C15.9094 5.72725 14.3866 6.98022 14.2474 7.87765C14.1467 8.54376 14.6629 9.08135 15.1127 9.4112C16.4599 10.4093 16.779 11.8807 15.9415 13.2536C15.0827 14.6694 13.2321 15.5904 11.221 15.6011C11.221 15.6011 9.25689 15.5968 8.14528 15.5947C7.8797 17.3317 3.69456 43.9332 3.37329 46.0258H20.0453C20.1909 45.0812 20.9706 40.0608 22.0201 33.404L22.0179 33.4083Z" fill={color} />
      <path d="M2.47372 52.1728H9.30187C12.2105 52.1728 12.9237 53.6464 12.7159 54.9957C12.5467 56.0924 11.8057 56.8955 10.5077 57.4267C12.1419 58.0436 12.7781 59.0181 12.5446 60.5238C12.2512 62.4236 10.5934 63.8436 8.43657 63.8436H0.666016L2.47372 52.1728ZM6.99512 57.0155C8.32948 57.0155 8.95275 56.313 9.08341 55.4755C9.22048 54.5867 8.80497 53.9527 7.47061 53.9527H6.29046L5.81497 57.0155H6.99512ZM6.26476 62.0659C7.63339 62.0659 8.43871 61.5176 8.61649 60.3717C8.76856 59.3951 8.20954 58.7975 6.87518 58.7975H5.54082L5.03535 62.0659H6.2669H6.26476Z" fill={color} />
      <path d="M22.1143 63.9657C20.3323 64.0857 19.4713 63.8972 19.0429 63.1261C18.0984 63.7087 17.0596 64 15.948 64C13.9282 64 13.2343 62.9569 13.4142 61.7918C13.4999 61.2435 13.8233 60.6973 14.3373 60.2518C15.4446 59.2944 18.1862 59.1552 19.2592 58.4205C19.3513 57.6002 19.0194 57.3068 18.0106 57.3068C16.8304 57.3068 15.8452 57.7009 14.1595 58.8468L14.5708 56.193C16.0358 55.1328 17.443 54.6552 19.0708 54.6552C21.159 54.6552 22.9946 55.5098 22.6412 57.7865L22.2428 60.3546C22.1014 61.2627 22.1293 61.5348 23.1359 61.554L22.1122 63.9657H22.1143ZM19.0258 59.9262C18.0769 60.526 16.3292 60.4231 16.1407 61.6547C16.0508 62.2373 16.4127 62.6656 16.9932 62.6656C17.5736 62.6656 18.2462 62.4258 18.7837 62.0509C18.7473 61.8453 18.7645 61.6226 18.8287 61.2113L19.0279 59.9262H19.0258Z" fill={color} />
      <path d="M25.4406 54.8094H29.0003L28.8161 55.9917C29.9405 55.0343 30.8016 54.6573 31.9324 54.6573C33.9179 54.6573 34.8432 55.8718 34.5326 57.8744L33.6074 63.8479H30.0476L30.8144 58.9024C30.9536 57.9965 30.6923 57.5852 29.9919 57.5852C29.4286 57.5852 28.9167 57.8936 28.4198 58.5598L27.6017 63.8501H24.0419L25.4406 54.8137V54.8094Z" fill={color} />
      <path d="M37.272 52.1728H40.8317L39.9407 57.9236L43.3484 54.8073H47.7477L43.3698 58.6583L46.8803 63.8436H42.4124L39.7051 59.6671H39.6709L39.024 63.8436H35.4643L37.272 52.1728Z" fill={color} />
      <path d="M49.8638 52.1728H53.9526L52.1449 63.8436H48.0561L49.8638 52.1728Z" fill={color} />
      <path d="M55.9402 52.1728H61.7938C66.3109 52.1728 67.6175 55.4584 67.1977 58.1635C66.7864 60.8151 64.6917 63.8458 60.7208 63.8458H54.1325L55.9402 52.175V52.1728ZM59.7312 61.0721C61.7167 61.0721 62.8091 60.0954 63.1325 58.0093C63.3702 56.4693 62.9033 54.9443 60.6972 54.9443H59.6006L58.6518 61.0721H59.7312Z" fill={color} />
      <path d="M44.4086 0H27.3896L25.1107 14.4124L28.0043 14.4188C29.5871 14.4102 31.0779 13.697 31.7204 12.6389C31.926 12.3027 32.0095 11.9985 32.0095 11.7222C32.0095 11.1247 31.609 10.6749 31.1999 10.3729C30.0776 9.54185 29.842 8.64871 29.842 8.04257C29.842 7.91406 29.8527 7.80054 29.8677 7.70202C30.1033 6.17704 32.1702 4.54282 34.8732 4.54282C36.4924 4.54282 37.7454 4.91978 38.4929 5.63087C39.1504 6.25414 39.4181 7.13229 39.2297 8.04257C39.0005 9.12848 37.9039 10.0366 37.272 10.4821C35.6271 11.6408 35.8434 12.6539 35.9484 12.9367C36.2868 13.8448 37.5912 14.4081 38.6085 14.4081H43.0186C43.0186 14.4081 43.0186 14.4145 43.0186 14.4231C49.0157 14.4702 52.2391 17.2267 51.2239 23.6479C50.2794 29.6129 45.7044 32.1831 40.2449 32.2452L38.0774 46.0343H41.2751C54.7536 46.0343 65.7626 37.3728 67.9259 23.7101C70.5989 6.78103 59.8383 0 44.4086 0Z" fill={color} />
    </svg>;
};