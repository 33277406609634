import { CounterpartyWithStaffer, CpRepresentationRole, CpStaff, CpUser } from "@dbt/commons/types";
import { useUnmount } from "@dbt/commons/hooks";
import { setCompany } from "api/client/user";
import { createContext, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { from, lastValueFrom, takeUntil } from "rxjs";
import { mutate } from "swr";
import { ApiRoutes } from "api/client/counterparties";
import { applicationPaths } from "constants/paths";
import { useRouter } from "next/router";
import log from "@dbt-capital/log";
export type ExtendedCpRespresentationRole = CpRepresentationRole & CpStaff;
export interface UserState {
  user: CpUser;
  selectedCompanyId: string | null;
  multipleCompanies: boolean;
  hasConnectedCompanies: boolean;
  currentRepresentation: ExtendedCpRespresentationRole;
  selectCompany(id: string): Promise<CounterpartyWithStaffer>;
}
export const UserContext = createContext<UserState>(({} as UserState));
export const UserProvider = ({
  user,
  children,
  orgId
}: PropsWithChildren<{
  orgId: string | null;
  user: CpUser;
}>) => {
  const unmount$ = useUnmount();
  const multipleCompanies = user.representations.length > 1;
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(orgId);
  const router = useRouter();
  const selectCompany = useCallback((id: string) => {
    const observable = from(setCompany(id)).pipe(takeUntil(unmount$));
    observable.subscribe({
      next: counterparty => {
        mutate(ApiRoutes.getCounterparty(id), counterparty, {
          revalidate: false
        });
        router.push(applicationPaths.application);
        setSelectedCompanyId(id);
      },
      error: error => {
        setSelectedCompanyId(id);
        log.error(error);
      }
    });
    return lastValueFrom(observable);
  }, [unmount$]);
  const hasConnectedCompanies = user.representations.length !== 0;
  const currentRepresentation = useMemo(() => (user.representations.find(r => r.orgId === selectedCompanyId) as ExtendedCpRespresentationRole), [selectedCompanyId, user]);
  const value = useMemo(() => ({
    user,
    selectedCompanyId,
    selectCompany,
    multipleCompanies,
    hasConnectedCompanies,
    currentRepresentation
  }), [currentRepresentation, hasConnectedCompanies, multipleCompanies, selectCompany, selectedCompanyId, user]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};