import { dbtTheme } from "../../../configs/dbtTheme";
export const HamburgerMenuBadge = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 6.5H4V5.5H14V6.5Z" fill={color} />
      <path d="M20 12.5H4V11.5H20V12.5Z" fill={color} />
      <path d="M4 18.5H20V17.5H4V18.5Z" fill={color} />
      <path d="M22 6C22 7.65685 20.6569 9 19 9C17.3431 9 16 7.65685 16 6C16 4.34315 17.3431 3 19 3C20.6569 3 22 4.34315 22 6Z" fill="#D6001D" />
    </svg>;
};