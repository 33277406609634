import { dbtTheme } from "../../../configs/dbtTheme";
export const UserProfile = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" fill={color} />
      <path d="M18.2044 15.7039L12 13.9805L5.7956 15.7039C3.84791 16.2449 2.5 18.0183 2.5 20.0397V21.9994H3.5V20.0397C3.5 18.4675 4.54838 17.0882 6.06325 16.6674L12 15.0183L17.9368 16.6674C19.4516 17.0882 20.5 18.4675 20.5 20.0397V21.9994H21.5V20.0397C21.5 18.0183 20.1521 16.2449 18.2044 15.7039Z" fill={color} />
    </svg>;
};