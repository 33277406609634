import _styled from "@emotion/styled";
import { dbtTheme } from "../../configs/dbtTheme";
import { NavbarItem } from "./NavbarItem";
const StyledNavbar = _styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 236px;
  gap: 4px;
  background-color: ${dbtTheme.colors.background.light};
  height: 100%;
`;
export interface MenuItem {
  id: string;
  icon?: JSX.Element;
  label: string;
  path: string;
  active: boolean;
  disabled: boolean;
  description?: string;
  visible?: boolean;
  notifications?: number;
}
interface NavbarProps {
  navbarItems: MenuItem[];
  onClick(e: MenuItem): void;
}
export const Navbar = ({
  navbarItems,
  onClick
}: NavbarProps) => {
  return <StyledNavbar>
      {navbarItems.map(item => <NavbarItem key={item.id} item={item} onClick={onClick} notifications={item.notifications} data-testid={`desktopNavbarMenuItem--${item.label}`} />)}
    </StyledNavbar>;
};