/**
 * NOTE : This file export multiple helper hooks, functions, constants related to responsive design.
 *        Like getting the current breakpoint, current screen width, etc...
 *
 *        Summary of major Hooks/Functions that will be useful.
 *          - useScreen           : Get the current browser window size.
 *          - useWindowSize       : Get the current viewport size.
 *          - useMediaQuery       : Get the true/false value according to given media query matched or not. Useful when we need to match multiple breakpoint range or any custom media query.
 *          - useOrientation      : Get the current viewport is in portrait or landscape mode.
 *          - useDeviceData       : Get lot of data like browserType, deviceType, osType, etc... which will be parsed from browsers user-agent string.
 *          - useMobileDevice     : Get data relevant to check, whether user is on mobile device or not.
 *          - useCurrentBreakpoint: Get the current breakpoint name (Like "xs, sm, md, ....") and more data.

 *        Also note that some of these utilities are copied from "https://usehooks-ts.com/react-hook/".
 *
 */

export * from "./constants";
export * from "./useScreen";
export * from "./useWindowSize";
export * from "./useMediaQuery";
export * from "./useOrientation";
export * from "./useDeviceData";
export * from "./useMobileDevice";
export * from "./useCurrentBreakpoint";