import { dbtTheme } from "../../../configs/dbtTheme";
export const MyData = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8396 16.2287L16.8199 8.37948L16.0245 7.77344L10.7321 14.7197L8.11363 12.1421L7.41211 12.8547L10.8396 16.2287Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3691 6.15029L12.1228 2.92969L4.61914 6.14553V13.4752C4.61914 15.3693 5.9633 16.9055 7.44827 18.0882C8.62769 19.0276 9.99595 19.8193 11.0678 20.4394C11.3573 20.607 11.6252 20.762 11.8619 20.904L12.1191 21.0583L12.3764 20.904C12.56 20.7938 12.76 20.6762 12.9725 20.5513C14.0583 19.9128 15.4696 19.083 16.6733 18.0815C18.1018 16.893 19.3691 15.3583 19.3691 13.4752V6.15029ZM5.61914 13.4752V6.80493L12.1155 4.02077L18.3691 6.80017V13.4752C18.3691 14.8922 17.4073 16.1699 16.0337 17.3127C14.8991 18.2567 13.5729 19.0373 12.4854 19.6773C12.3599 19.7512 12.2372 19.8234 12.1187 19.8935C11.9323 19.7839 11.7356 19.67 11.5312 19.5517C10.4637 18.9336 9.18586 18.1937 8.07126 17.306C6.62915 16.1574 5.61914 14.8812 5.61914 13.4752Z" fill={color} />
    </svg>;
};