import { buildUrl, getOrThrowResponse } from "@dbt/commons/utils";
import { MontoIntegration } from "@dbt/types/monto";
export async function getMontoConnectUrl(orgId: string, orgName: string | null, erpSystem: MontoIntegration) {
  const response = await fetch(buildUrl("/api/monto-erp/prepare-connection", {
    orgId,
    orgName,
    erpSystem
  }));
  return getOrThrowResponse(response);
}
export async function getConnectedOrgByMontoUUID(montoCompanyUUID: string) {
  const response = await fetch(buildUrl("/api/monto-erp/get-connection", {
    montoCompanyUUID
  }));
  return getOrThrowResponse(response);
}
export async function updateConnectedOrgByMontoUUID(montoCompanyUUID: string, ts: string) {
  const response = await fetch(buildUrl("/api/monto-erp/update-connection", {
    montoCompanyUUID,
    ts
  }));
  return getOrThrowResponse(response);
}
export async function getConnectedCompany(montoCompanyUUID: string) {
  const response = await fetch(buildUrl("/api/monto-erp/get-connected-company", {
    montoCompanyUUID
  }));
  return getOrThrowResponse(response);
}