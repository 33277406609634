import { getOrThrowResponse } from "@dbt/commons/utils";
interface AgreementData {
  orgId: string;
  orgName: string;
  type: string;
  amount: number;
  initialAmount: number;
}
interface DrawdownData {
  orgId: string;
  orgName: string;
  type: string;
  amount: number;
  loanPeriod: number;
  gracePeriod: number;
}
export async function sendSlackMessageAgreement(data: AgreementData | DrawdownData): Promise<any> {
  const response = await fetch(`/api/slack/loan-application`, {
    method: "POST",
    body: JSON.stringify({
      ...data
    })
  });
  return getOrThrowResponse(response);
}