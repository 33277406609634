import { StaffPortalErrorCode } from "../types/error";
function getFunctionName(response: Response, responseType?: XMLHttpRequestResponseType) {
  switch (responseType) {
    case "blob":
      return "blob";
    case "text":
      return "text";
    default:
      return "json";
  }
}
export async function getOrThrowResponse(response: Response, responseType?: XMLHttpRequestResponseType) {
  if (response.redirected) {
    if (response.url.replace(location.origin, "") === "/login") {
      location.href = response.url;
      return;
    }
  }
  const functionName = getFunctionName(response, responseType);
  try {
    const data = await response[functionName]();
    if (response.ok) {
      return data;
    } else {
      throw {
        ...data,
        statusCode: response.status
      };
    }
  } catch (error: any) {
    if (response.ok) {
      return {};
    } else {
      if (error?.error_details?.includes(StaffPortalErrorCode.UnauthorizedError)) {
        location.reload();
        alert("Sessionen har uphört. Logga in igen för att fortsätta");
        return;
      }

      // Handling "Forbidden Access" error due to user status is set to "removed" type. (In Customer Portal)
      // In this case we reload the page, So essentially we render appropriate action component to user, which mainly handled in "new-customer-portal/src/LoggedIn.tsx" file.
      if (error?.error === "Forbidden Access" && error?.error_details.includes("removed not equal to required status")) {
        location.reload();
        return;
      }
      throw {
        message: response.statusText,
        statusCode: response.status,
        error_details: "",
        ...error
      };
    }
  }
}
export function buildUrl(baseUrl: string, searchParams: Record<string, string | number | undefined | null | boolean | string[]>) {
  const params = new URLSearchParams();
  Object.entries(searchParams).forEach(([key, value]) => {
    if (value !== undefined) {
      if (typeof value === "boolean") {
        if (value) {
          params.append(key, String(value));
        }
      } else if (Array.isArray(value)) {
        value.forEach(v => {
          params.append(key, v);
        });
      } else {
        params.append(key, String(value));
      }
    }
  });
  return `${baseUrl}?${params.toString()}`;
}
export const retryFetch = async (url: string, retries: number, retryInterval: number) => {
  let err;
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url);
      return response;
    } catch (e) {
      err = e;
    }
    await delay(retryInterval);
  }
  throw err;
};
const delay = (d: number) => new Promise(resolve => setTimeout(resolve, d));