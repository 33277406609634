import React from "react";
import { Global, css } from "@emotion/react";
import { responsiveBreakpointsList } from "../hooks";

// For easiness, re-exporting helper utilities relevant to dbtTheme.
// So in other places we can just import these helper utilities along with dbtTheme in single line.
export * from "../hooks/responsiveUtilities";
export const dbtTheme = {
  colors: {
    background: {
      light: "#fffff5",
      dark: "#3c3214",
      page: "#ecebdf",
      backdrop: "#0000004d",
      light_blue: "#E5F1FA",
      light_yellow: "#FFF6BB",
      error_soft: "#FCE6E9",
      dark_hover: "#635b43"
    },
    primary: {
      light: "#009b37",
      light_10: "#009b3712",
      dark: "#004B0F"
    },
    secondary: {
      light: "#ffdc00",
      success: "#009B37",
      error: "#E1001E"
    },
    text: {
      white: "#ffffff",
      black: "#000000",
      gray: "#969696",
      dark: "#3c3214",
      green: "#009B37",
      red: "#E1001E"
    },
    status: {
      info_10: "#E5F1FA",
      info_100: "#006BB3",
      success_10: "#E5F5EB",
      success_100: "#00802D",
      error_10: "#FCE6E9",
      error_100: "#D6001D"
    },
    other: {
      border_lines_dark: "#3c3214",
      border_lines_light: "#3c321440",
      border_inactive: "#ceccc4",
      border_table: "#ebeae7"
    },
    bw: {
      light: "#ffffff",
      light_100: "#ffffff",
      light_90: "#ffffffe6",
      light_50: "#ffffff80",
      light_30: "#ffffff4d",
      dark: "#000000",
      dark_100: "#000000",
      dark_90: "#000000e6",
      dark_50: "#00000080",
      dark_30: "0000004d",
      gray: "#f3f3f3",
      gray_100: "#3c3c3c",
      gray_80: "#676767",
      gray_60: "#969696",
      gray_40: "#c0c0c0",
      gray_20: "#d0d0d0",
      gray_5: "#f3f3f3"
    },
    graphs: {
      green1_100: "#004B0F",
      green2_100: "#009B37"
    },
    // Colors in new design system.
    black: "#000000",
    white: "#ffffff",
    grey_90: "#1a1a1a",
    grey_80: "#3c3c3c",
    grey_60: "#666666",
    grey_50: "#808080",
    grey_40: "#999999",
    grey_30: "#b2b2b2",
    grey_20: "#cccccc",
    grey_10: "#e5e5e5",
    grey_03: "#f7f7f7",
    primary01_100: "#009b37",
    primary01_10: "#e5f5eb",
    primary01_7: "#edf8f1",
    primary02_100: "#004b0f",
    secondary01_100: "#ffdc00",
    secondary01_25: "#fff6bf",
    secondary01_20: "#fff8cc",
    tertiary01_165: "#595956",
    tertiary01_140: "#999993",
    tertiary01_120: "#ccccc4",
    tertiary01_105: "#f2f2e9",
    tertiary01_100: "#fffff5",
    tertiary01_25: "#fffffd",
    tertiary02_130: "#120f06",
    tertiary02_100: "#3c3214",
    tertiary02_80: "#635b43",
    tertiary02_67: "#7c7662",
    tertiary02_30: "#c5c1b9",
    tertiary02_25: "#ceccc4",
    tertiary02_10: "#ebeae7",
    tertiary02_08: "#efefec",
    tertiary02_05: "#f5f5f3",
    status_success_100: "#00802d",
    status_success_10: "#e5f5eb",
    status_warning_100: "#ffdc00",
    status_warning_20: "#fff8cc",
    status_warning_10: "#fffbe6",
    status_error_100: "#d6001d",
    status_error_20: "#f9ccd2",
    status_error_10: "#fce6e9",
    status_info_100: "#006bb3",
    status_info_20: "#f7d4cc",
    status_info_10: "#e5f1fa",
    status_active_100: "#3c3214",
    graph01_100: "#009b37",
    graph01_20: "#ccebd7",
    graph02_100: "#ffdc00",
    graph02_20: "#fff29d",
    graph03_100: "#00a0ff",
    graph03_30: "#b1e2ff",
    graph04_100: "#8b60ff",
    graph04_35: "#d6c7ff",
    graph05_100: "#e1001e",
    graph05_30: "#f6b3bc"
  },
  fontSizes: {
    default: "16px",
    h1: "80px",
    h2: "64px",
    h3: "48px",
    h4: "40px",
    h5: "32px",
    h6: "24px",
    p: "16px",
    xLarge: "160px",
    large: "100px",
    small: "14px",
    xSmall: "12px",
    default__mobile: "16px",
    h1__mobile: "48px",
    h2__mobile: "40px",
    h3__mobile: "32px",
    h4__mobile: "24px",
    h5__mobile: "16px",
    h6__mobile: "14px",
    p__mobile: "16px",
    xLarge__mobile: "64px",
    large__mobile: "48px",
    small__mobile: "12px",
    xSmall__mobile: "10px"
  },
  lineHeight: {
    default: "130%",
    h1: "110%",
    h2: "110%",
    h3: "110%",
    h4: "110%",
    h5: "110%",
    h6: "120%",
    p: "150%"
  },
  fontWeights: {
    100: "100",
    200: "200",
    300: "300",
    400: "400",
    // normal
    500: "500",
    600: "600",
    700: "700",
    // bold
    800: "800",
    900: "900"
  },
  fontFamily: {
    title_condensed: '"Roslindale Display Condensed", serif',
    title_regular: '"Roslindale Regular", serif',
    normal: `"PP Neue Montreal TT", sans-serif`,
    fancy_regular: `"BonVivant Regular", cursive`,
    fancy_serif: `"BonVivant Serif", cursive`
  },
  // NOTE : If you are using tailwind values in the project too,
  //        manually add/update that project's "tailwind.config.js --> theme.screens" object with below values. So they are in sync.
  breakpoints: responsiveBreakpointsList,
  custom: {
    /* NOTE : Using this value sync page <Header>'s description and <InfoSection>'s description width.
              This is needed make them visually look like it's in a same line with each other, whenever possible.
    */
    header_description_max_width: "550px"
  }
};
export interface IDBTThemeProviderProps {
  children: React.ReactNode;
}

// Temporary simple wrapper to set global like style defaults in a project, Until we decide exact ways to handle theme data and etc..
// Normally should be wrapped on "_app.tsx" like level.
export const DBTThemeProvider = ({
  children
}: IDBTThemeProviderProps) => {
  return <>
      <Global styles={css`
          body {
            font-size: ${dbtTheme.fontSizes.default};
            font-family: ${dbtTheme.fontFamily.normal};
            color: ${dbtTheme.colors.text.dark};
            background-color: ${dbtTheme.colors.background.page};
          }

          .dbtThemeWrapper {
            display: flex;

            min-height: 100vh; /* Fallback */
            min-height: 100dvh;
          }
        `} />
      <div className="dbtThemeWrapper">
        <div style={{
        flex: 1
      }}>{children}</div>
      </div>
    </>;
};