import { dbtTheme } from "../../../configs/dbtTheme";
export const Link = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.18133 16.5C5.61703 16.5 3.5 14.5059 3.5 12C3.5 9.49406 5.61703 7.5 8.18134 7.5H10.5V8.5L8.18133 8.5C6.12707 8.5 4.5 10.0877 4.5 12C4.5 13.9123 6.12707 15.5 8.18133 15.5L10.5 15.5V16.5H8.18133Z" fill={color} />
      <path d="M13.5 7.5H15.8187C18.383 7.5 20.5 9.49406 20.5 12C20.5 14.5059 18.383 16.5 15.8187 16.5H13.5V15.5H15.8187C17.8729 15.5 19.5 13.9123 19.5 12C19.5 10.0877 17.8729 8.5 15.8187 8.5H13.5V7.5Z" fill={color} />
      <path d="M10 11.5C9.72386 11.5 9.5 11.7239 9.5 12C9.5 12.2761 9.72386 12.5 10 12.5H14C14.2761 12.5 14.5 12.2761 14.5 12C14.5 11.7239 14.2761 11.5 14 11.5H10Z" fill={color} />
    </svg>;
};