import { ErpOrgSourceUnbalancedAccounts } from "@dbt/commons/types";
import { FinDataSet, formatDate } from "@dbt/commons/utils";
import { endOfMonth, endOfYear, startOfYear, subMonths } from "date-fns";
export function getYears(count = 3) {
  const date = new Date();
  const toDate = formatDate(endOfYear(date));
  date.setFullYear(date.getFullYear() - count);
  const fromDate = formatDate(startOfYear(date));
  return {
    toDate,
    fromDate
  };
}
function isUpToDate(year: number, month: number, monthsObj: Record<string, any>[]) {
  if (monthsObj.length === 0) return false;
  const latestMonthObj = monthsObj[monthsObj.length - 1];
  return latestMonthObj.year > year || latestMonthObj.year === year && latestMonthObj.month >= month;
}
function captureYearAndMonth(label: string) {
  const pos = label.indexOf("-");
  if (pos > 0) {
    return {
      year: Number(label.substring(0, pos)),
      month: Number(label.substring(pos + 1)) - 1
    };
  }
  return {
    year: 0,
    month: 0
  };
}
function populateFromTo(fyFrom: string, fyTo: string) {
  const records: Record<string, any>[] = [];
  const {
    year: fYear,
    month: fMonth
  } = captureYearAndMonth(fyFrom);
  const {
    year: tYear,
    month: tMonth
  } = captureYearAndMonth(fyTo);
  let y = fYear,
    m = fMonth;
  records.push({
    year: y,
    month: m
  });
  if (tYear < fYear) {
    return ([] as Record<string, any>[]);
  }
  while (true) {
    m++;
    if (m === 12) {
      y++;
      m = 0;
    }

    // passed the year
    if (y > tYear) break;
    // month has passed
    if (y == tYear && m > tMonth) break;
    records.push({
      year: y,
      month: m
    });
  }
  return records;
}
export interface IGetBookkeepingYearsReturn {
  yearsWithData: string[];
  yearsWithoutData: string[];
  hasEnoughYearsOfBookkeepingData: boolean;
}

/**
 * Current logic: Todays date minus 2 months = current year
 * current year + last two years
 */
export function getBookkeepingYears(actuals?: FinDataSet | null, unbalancedData?: ErpOrgSourceUnbalancedAccounts[] | null): IGetBookkeepingYearsReturn {
  const todaysDate = new Date();
  const lastRequiredEndDate = endOfMonth(subMonths(todaysDate, 2));
  const currentYear = lastRequiredEndDate.getFullYear();
  const previousYear = lastRequiredEndDate.getFullYear() - 1;
  const twoYears = lastRequiredEndDate.getFullYear() - 2;
  const yearsThatNeedData = [currentYear, previousYear, twoYears];
  const initialYears = new Array(3).fill(null).reduce<{
    [key: number]: boolean;
  }>((acc, curr, i) => {
    acc[currentYear - i] = false;
    return acc;
  }, {});
  const monthsFromActuals = actuals ? actuals.actualData.reduce<Record<string, any>[]>((acc, curr, index) => {
    const hasData = !curr.every(data => isNaN(data));
    if (hasData) {
      acc.push(actuals.months[index]);
    }
    return acc;
  }, []) : [];
  const unbalancedMonths: Record<string, any>[] = [];
  if (unbalancedData && unbalancedData.length > 0) {
    unbalancedData.forEach(ud => {
      if (ud.accounts && ud.accounts.length > 0) {
        const fyStart = ud.fyStartMonth;
        const fyLast = ud.accounts[0].fyLastMonth;
        unbalancedMonths.push(...populateFromTo(fyStart, fyLast));
      }
    });
  }
  const months = [...monthsFromActuals, ...unbalancedMonths];
  const currentYearMonths: number[] = [];
  const data = months.reduce<{
    temp: Record<string, any>;
    yearsWithData: typeof initialYears;
    yearsWithoutData: typeof initialYears;
  }>((acc, {
    year,
    month
  }) => {
    acc.temp[year] = acc.temp[year] || 0;
    acc.temp[year]++;
    if (year === currentYear) {
      currentYearMonths.push(month);
    }
    if (acc.temp[year] === 11) {
      acc.yearsWithData[year] = true;
      delete acc.yearsWithoutData[year];
    }
    return acc;
  }, {
    temp: {},
    yearsWithData: {},
    yearsWithoutData: {
      ...initialYears
    }
  });
  const dataIsUpToDate = isUpToDate(lastRequiredEndDate.getFullYear(), lastRequiredEndDate.getMonth(), months);

  // If we have enough SIE data for current year as well, according to current logic.
  if (dataIsUpToDate) {
    data.yearsWithData[currentYear] = true;
    delete data.yearsWithoutData[currentYear];
  }
  const yearsWithData = Object.keys(data.yearsWithData);
  const yearsWithoutData = Object.keys(data.yearsWithoutData);
  const hasEnoughYearsProvided = yearsThatNeedData.every(year => {
    return yearsWithData.includes(String(year));
  });
  const hasEnoughYearsOfBookkeepingData = dataIsUpToDate && hasEnoughYearsProvided;
  return {
    yearsWithData,
    yearsWithoutData,
    hasEnoughYearsOfBookkeepingData
  };
}