import { buildUrl, getOrThrowResponse } from "@dbt/commons/utils";
import { Agreement } from "@dbt/commons/types";
export const ApiRoutes = {
  getAgreements: (orgId: string) => buildUrl("/api/portal/get-agreements", {
    orgId
  })
};
export async function getAgreements(orgId: string): Promise<Agreement[]> {
  const response = await fetch(ApiRoutes.getAgreements(orgId), {
    method: "GET"
  });
  return getOrThrowResponse(response);
}