import { useMediaQuery } from "./useMediaQuery";
export interface IUseOrientationReturn {
  orientation: "portrait" | "landscape";
  isPortrait: boolean;
  isLandscape: boolean;
}
export const useOrientation = (): IUseOrientationReturn => {
  const isPortrait = useMediaQuery("(orientation: portrait)");
  return {
    orientation: isPortrait ? "portrait" : "landscape",
    isPortrait,
    isLandscape: !isPortrait
  };
};