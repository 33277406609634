import _styled from "@emotion/styled";
import { useUser } from "hooks/contexts";
import { useCallback, useState } from "react";
import { CompanyRow } from "../modals/CompanyRow";
import { useCurrentBreakpoint } from "@dbt/commons/hooks";
import { Panel } from "@dbt/commons/components/panel";
import { Text } from "@dbt/commons/components/text";
import { Button } from "@dbt/commons/components/button";
import { ArrowRight } from "@dbt/commons/components/icons/24px";
import { css, cx } from "@emotion/css";
import { useTranslation } from "next-i18next";
const ContentContainer = _styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100%;
`;
const panelDesktop = css`
  height: 600px;
  width: 581px;
  overflow-y: auto;
`;
const CompanyContainer = _styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  overflow-y: auto;
  width: 100%;
`;
export const SelectCompanyModal = () => {
  const {
    user,
    selectCompany
  } = useUser();
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);
  const handleSelect = useCallback((orgId: string) => {
    setSelectedOrgId(orgId);
  }, []);
  const handleConfirm = useCallback(() => {
    if (selectedOrgId) {
      selectCompany(selectedOrgId);
    }
  }, [selectedOrgId, selectCompany]);
  const {
    isDesktop
  } = useCurrentBreakpoint();
  const {
    t
  } = useTranslation("Common");
  return <Panel className={isDesktop ? cx(panelDesktop) : ""} data-testid="selectCompanyModalSection">
      <ContentContainer>
        <Text as="h3" font={"title_condensed"}>
          {t("selectCompany")}
        </Text>
        <CompanyContainer>
          {user.representations.map((representation, i) => {
          return <CompanyRow data-cy={`company-row-${i}`} data-testid={`company-row-${i}`} onClick={handleSelect} representation={representation} selected={selectedOrgId === representation.orgId} key={representation.orgId} tabIndex={0} />;
        })}
        </CompanyContainer>
        <Button variant="primary" color="positive" size="md" rightIcon={<ArrowRight />} style={{
        width: "100%",
        marginTop: "24px"
      }} onClick={handleConfirm} disabled={!!!selectedOrgId} data-testid="goToPortalButton">
          {t("toThePortal")}
        </Button>
      </ContentContainer>
    </Panel>;
};