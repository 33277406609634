import _styled from "@emotion/styled";
import { useCallback } from "react";
import { dbtTheme } from "../../configs/dbtTheme";
import { Button } from "../button";
import { Close } from "../icons/16px";
import { ArrowRight } from "../icons/24px";
import { ConnectedBox } from "./ConnectedBox";
import { MontoIntegration } from "@dbt/types/monto";
import { InProgressBox } from "./InProgressBox";
export const erpName = {
  manualSie: "manual sie",
  fortnox: "fortnox",
  bizzdo: "bizzdo",
  monto: "monto"
};
const ButtonWrapper = _styled.div`
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
`;
const StyledButton = _styled.button<{
  editMode?: boolean;
  hasErpConnection: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 15px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(60, 50, 20, 0.25);
  background-color: ${dbtTheme.colors.background.light};
  cursor: pointer;
  ${({
  hasErpConnection
}) => hasErpConnection && `pointer-events: none;`}
  :focus {
    outline: 2px solid ${dbtTheme.colors.primary.light};
  }
`;
const StyledRemoveErpActionWrapper = _styled.div<{
  editMode?: boolean;
}>`
  display: flex;
  gap: 10px;
  align-items: center;
  pointer-events: auto;
`;
interface ErpModuleProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  erpType: MontoIntegration;
  logo: JSX.Element | null;
  connectedErp: string | null | undefined;
  connectedErpSubsystem: MontoIntegration | null | undefined;
  userIsVerified: boolean;
  connectedLabel: string;
  connectToLabel: string;
  connectedBoxLabel: string;
  removeButtonLabel: string;
  "aria-label"?: string;
  "data-testid"?: string;
  editMode?: boolean;
  handleConnectErp(erpType?: MontoIntegration): void;
  handleRemoveErp(): void;
  isMobile?: boolean;
  isErpSyncing?: boolean;
}
export const ErpModule = ({
  erpType,
  logo,
  connectedErp,
  connectedErpSubsystem,
  editMode,
  userIsVerified,
  handleConnectErp,
  handleRemoveErp,
  removeButtonLabel,
  connectedLabel,
  connectToLabel,
  connectedBoxLabel,
  isMobile = false,
  isErpSyncing = false,
  ...rest
}: ErpModuleProps) => {
  const hasErpConnection = !!(connectedErp && connectedErp !== erpName.manualSie);

  // Helper function to avoid triggering parent onClick handler - handleConnectErp
  const removeErp = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleRemoveErp();
  }, [handleRemoveErp]);
  const connectErp = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isErpSyncing) {
      e.stopPropagation();
      handleConnectErp(erpType);
    }
  }, [erpType, isErpSyncing, handleConnectErp]);
  const renderErpConnection = useCallback(() => {
    // if only sie files have been uploaded, show logo instead and disable "edit"
    // option, so that the user can still connect their bookkeeping system without
    // showing a "connected" status
    if (!hasErpConnection || connectedErp === erpName.manualSie) return logo;
    if (connectedErp === erpName.bizzdo) return connectedLabel;
    if (connectedErp === erpName.fortnox || connectedErp === erpName.monto) return logo;
  }, [hasErpConnection, connectedErp, logo, connectedLabel]);
  const renderRightSideElement = useCallback(() => {
    if (isErpSyncing) {
      return <InProgressBox data-testid="erpConnectionInProgressStatusBox" />;
    }
    if (!hasErpConnection || connectedErp === erpName.manualSie) {
      if (isMobile) {
        return <ArrowRight data-testid="erpConnectButton" />;
      } else {
        return <ButtonWrapper>
            <Button variant="primary" color="positive" size="lg" rightIcon={<ArrowRight />} fullWidth onClick={connectErp} id={`ga-databokföring-${erpType}`} data-testid="erpConnectButton">
              {connectToLabel}
            </Button>
          </ButtonWrapper>;
      }
    }
    if (hasErpConnection) {
      if (editMode && userIsVerified) {
        return <StyledRemoveErpActionWrapper onClick={removeErp}>
            <Button variant="tertiary" color="positive" size="md" underlined rightIcon={<Close />} data-testid="removeErpConnectionButton">
              {removeButtonLabel}
            </Button>
          </StyledRemoveErpActionWrapper>;
      } else {
        return <ConnectedBox label={connectedBoxLabel} data-testid="erpConnectionConnectedStatusBox" />;
      }
    }
  }, [isErpSyncing, hasErpConnection, connectedErp, isMobile, connectErp, erpType, connectToLabel, editMode, userIsVerified, removeErp, removeButtonLabel, connectedBoxLabel]);

  // we don't render this component,
  //  if connected erp subsystem is not equal to the given erp type of this module
  if (hasErpConnection && connectedErpSubsystem && connectedErpSubsystem !== erpType) {
    return null;
  }
  return <StyledButton editMode={editMode} hasErpConnection={hasErpConnection} onClick={connectErp} aria-label={rest["aria-label"]} data-testid={rest["data-testid"]}>
      {renderErpConnection()}
      {renderRightSideElement()}
    </StyledButton>;
};