import { dbtTheme } from "../../../configs/dbtTheme";
interface SpinnerProps {
  size?: number | string;
  color?: string;
}
export const Spinner = (props: SpinnerProps) => {
  const {
    size = "auto",
    color = dbtTheme.colors.primary01_100
  } = props;
  return <svg width={size} height={size} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36 68C29.671 68 23.4841 66.1232 18.2218 62.607C12.9594 59.0908 8.85786 54.0931 6.43585 48.2459C4.01385 42.3986 3.38014 35.9645 4.61487 29.7571C5.8496 23.5497 8.8973 17.8479 13.3726 13.3726C17.8479 8.8973 23.5497 5.8496 29.7571 4.61487C35.9645 3.38014 42.3986 4.01385 48.2459 6.43586C54.0931 8.85786 59.0908 12.9594 62.607 18.2218C66.1232 23.4841 68 29.671 68 36" stroke="url(#paint0_linear_10272_13268)" strokeWidth="8" strokeLinecap="round" />
      <defs>
        <linearGradient id="paint0_linear_10272_13268" x1="36" y1="4" x2="36" y2="68" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>;
};