// Block transaction if request URL contains any of below
const BLACKLISTED_URLS = ["/api/health", "/api/sentry/tunnel", "/api/auth/session", "/api/auth/csrf"];
export const getSentryDsn = () => {
  const dsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
  return dsn === "NOT_SET" ? undefined : dsn;
};
export function getEnvironmentClass() {
  return process.env.ENVIRONMENT_CLASS ?? process.env.NEXT_PUBLIC_ENVIRONMENT_CLASS ?? "unknown";
}
export const isEnabled = () => {
  const dsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
  return !(dsn === undefined || dsn === "NOT_SET");
};
export const tracesSampler = (defaultSamplingRate: number, blacklistedUrls = BLACKLISTED_URLS) => {
  // TODO: Find a nice way to import SamplingContext type from Sentry
  return (samplingContext: any) => {
    if (blacklistedUrls.some(url => samplingContext.request?.url?.includes(url))) {
      return 0.0;
    }

    // If our parent was sent to Sentry, continue!
    if (samplingContext?.parentSampled) {
      return 1.0;
    }
    return defaultSamplingRate;
  };
};