import _styled from "@emotion/styled";
import { dbtTheme } from "../../configs/dbtTheme";
const BadgeContainer = _styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${dbtTheme.colors.status.error_100};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BadgeText = _styled.p`
  color: ${dbtTheme.colors.background.light};
  font-size: 14px;
  margin: 0;
`;
interface BadgeProps {
  notifications: number;
}
export const Badge: React.FC<BadgeProps> = ({
  notifications
}) => {
  return <BadgeContainer>
      <BadgeText>{notifications}</BadgeText>
    </BadgeContainer>;
};