import _styled from "@emotion/styled";
import { AppProps } from "AppRoot";
import { LoadingComponent } from "@dbt/commons/components/loading";
import { useCounterparty } from "hooks/api";
import { useMenu } from "hooks/contexts";
import { useRouter } from "next/router";
import { useMemo } from "react";
const LoadingContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center"
});
export default function RouteContainer({
  pageProps,
  Component
}: AppProps) {
  const router = useRouter();
  const {
    menuItems
  } = useMenu();
  const menuItem = useMemo(() => menuItems.find(m => m.path === router.pathname), [router.pathname, menuItems]);
  const {
    loading: counterpartyLoading
  } = useCounterparty();
  // Don't show routes that are disabled or not visible
  const loading = menuItem?.disabled || menuItem?.visible === false || counterpartyLoading;
  if (loading) {
    return <LoadingContainer>
        <LoadingComponent size={120} data-testid="routeLoadingIndicator" />
      </LoadingContainer>;
  }
  return <Component {...pageProps} />;
}