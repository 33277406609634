/* eslint-disable @typescript-eslint/no-var-requires */
const LocizeBackend = require("i18next-locize-backend/cjs");
const locizeProjectId = require("./constants").locizeProjectId;

const isProd =
  process.env.ENVIRONMENT_CLASS === "production" ||
  process.env.NEXT_PUBLIC_ENVIRONMENT_CLASS === "production";

// next-i18next.config.js
module.exports = {
  i18n: {
    defaultLocale: "sv",
    locales: ["sv"],
  },
  backend: {
    projectId: locizeProjectId,
    referenceLng: "sv",
    version: isProd ? "production" : "latest",
  },
  use: [LocizeBackend],
  ns: [
    "BankID",
    "Application",
    "Common",
    "Drawdown",
    "Loans",
    "Reports",
    "Settings",
    "Klarna",
    "BookKeeping",
    "Documents",
  ], // the namespaces needs to be listed here, to make sure they got preloaded
  serializeConfig: false, // because of the custom use i18next plugin
  debug: process.env.NODE_ENV === "development" ? true : false,
};
