import { buildUrl, getOrThrowResponse } from "@dbt/commons/utils";
import { Application } from "@dbt/commons/types";
export const ApiRoutes = {
  getApplications: (orgId: string, email?: string | null) => buildUrl("/api/portal/applications", {
    orgId,
    email
  })
};
export async function getApplications(orgId: string, email?: string | null): Promise<Application> {
  const response = await fetch(ApiRoutes.getApplications(orgId, email), {
    method: "GET"
  });
  return getOrThrowResponse(response);
}