import _styled from "@emotion/styled";
import { dbtTheme } from "../../configs/dbtTheme";
import { triggerWithEnterOrSpaceKey } from "../../utils/dom_events";

// TODO: Those hard coded styled need use theme once we have set it up

const font = `
    /* Neue Montreal /16 - 110 (Button) */

    font-family: 'PP Neue Montreal TT';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
`;
const light = `
    /* Brand/Sunbeam */
    color: #FFFFF5;
`;
const dark = `
    /* Brand/Earth Brown */
    color: #3C3214;
`;
const StyledLink = _styled.a<{
  variant?: "dark" | "light";
  underline: boolean;
}>`
  padding: 4px;
  cursor: pointer;
  ${font}
  ${({
  variant
}) => variant === "dark" ? dark : light}
  ${({
  underline
}) => underline && `
  text-decoration: underline;
  `}
    :focus {
    outline: none;
    padding: 2px;
    border: 2px solid ${dbtTheme.colors.primary.light};
    border-radius: 4px;
  }
`;
export interface LinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  variant?: "dark" | "light";
  underline?: boolean;
  href?: string;
  children: React.ReactNode;
}
export const Link = (props: LinkProps) => {
  const {
    variant = "dark",
    underline = true,
    href,
    children,
    ...rest
  } = props;
  return <StyledLink tabIndex={0} onKeyPress={triggerWithEnterOrSpaceKey(props.onClick)} variant={variant} underline={underline} href={href} {...rest}>
      {children}
    </StyledLink>;
};