import _styled from "@emotion/styled";
import React from "react";
import { PolymorphicComponentPropsWithRef, PolymorphicRef } from "../../types/polymorphic";
import { dbtTheme } from "../../configs/dbtTheme";
type TFontFamily = "title_condensed" | "title_regular" | "normal" | "fancy_regular" | "fancy_serif";

// TODO: propably can be put to another place. This is just to fix the indexing of theme property
type TKnownFontTags = "default" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
interface Props {
  children: React.ReactNode;
  font?: TFontFamily;
  size?: number;
  lineHeight?: string;
  bold?: boolean;
  underline?: boolean;
  color?: string;
}
const StyledText = _styled.div<Omit<Props, "children">>`
  ${({
  bold
}) => bold && "font-weight: 600;"}
  ${({
  underline
}) => underline && "text-decoration: underline;"}
  ${({
  color
}) => color && `color: ${color};`}
  ${({
  font
}) => {
  // Font Family
  if (font) {
    return `font-family: ${dbtTheme.fontFamily[(font as TFontFamily)]};`;
  } else {
    return `font-family: ${dbtTheme.fontFamily["normal"]};`;
  }
}}

  ${({
  as,
  size
}) => {
  // Font Size
  if (size) {
    return `font-size: ${size}px;`;
  } else if (as && dbtTheme.fontSizes.hasOwnProperty((as as TKnownFontTags))) {
    return `font-size: ${dbtTheme.fontSizes[(as as TKnownFontTags)]};`;
  } else {
    return `font-size: ${dbtTheme.fontSizes["default"]};`;
  }
}}

  ${({
  as,
  lineHeight
}) => {
  // Line Height
  if (lineHeight) {
    return `line-height: ${lineHeight};`;
  } else if (as && dbtTheme.lineHeight.hasOwnProperty((as as TKnownFontTags))) {
    return `line-height: ${dbtTheme.lineHeight[(as as TKnownFontTags)]};`;
  } else {
    return `line-height: ${dbtTheme.lineHeight["default"]};`;
  }
}}
`;
type TextProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;
type TextComponent = <C extends React.ElementType = "span">(props: TextProps<C>) => React.ReactElement | null;
export const Text: TextComponent = React.forwardRef(<C extends React.ElementType = "span",>({
  as,
  children,
  font,
  size,
  ...other
}: TextProps<C>, ref?: PolymorphicRef<C>) => {
  return <StyledText ref={ref} as={as || "span"} font={font} size={size} {...other}>
        {children}
      </StyledText>;
});