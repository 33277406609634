import { dbtTheme } from "../../../configs/dbtTheme";
export const WarningTriangleOutline = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4999 9.00552V14.5055H11.4999V9.00552H12.4999Z" fill={color} />
      <path d="M12.7499 18.5055V16.5055H11.2499V18.5055H12.7499Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3869 21.3889H3.61327C2.86988 21.3889 2.39011 20.6066 2.72256 19.9417L11.1045 3.17779C11.4731 2.44074 12.5249 2.44074 12.8934 3.17779L21.2753 19.9417C21.6078 20.6066 21.1302 21.3889 20.3869 21.3889ZM20.3809 20.3889L11.999 3.625L3.61699 20.3889L20.3809 20.3889Z" fill={color} />
    </svg>;
};