import { dbtTheme } from "../../../configs/dbtTheme";
export const Overview = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.07031 9.57031H14.0703V10.5703H6.07031V9.57031Z" fill={color} />
      <path d="M11.0703 12.5703H6.07031V13.5703H11.0703V12.5703Z" fill={color} />
      <path d="M6.07031 6.57031H14.0703V7.57031H6.07031V6.57031Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0714 15.361C18.8468 12.219 18.7321 7.41837 15.7272 4.41346C12.603 1.28926 7.53765 1.28926 4.41346 4.41346C1.28926 7.53765 1.28926 12.603 4.41346 15.7272C7.41945 18.7332 12.2225 18.8469 15.3644 16.0683L21.2168 21.9258L21.9242 21.219L16.0714 15.361ZM15.0201 15.0201C17.7537 12.2864 17.7537 7.85424 15.0201 5.12057C12.2864 2.38689 7.85424 2.38689 5.12057 5.12057C2.38689 7.85424 2.38689 12.2864 5.12057 15.0201C7.85424 17.7537 12.2864 17.7537 15.0201 15.0201Z" fill={color} />
    </svg>;
};