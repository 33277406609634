import _styled from "@emotion/styled";
import { dbtTheme } from "../../configs/dbtTheme";
const PanelContainer = _styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 40px;
  max-width: 581px;
  background: ${dbtTheme.colors.background.light};
  border-radius: 12px;
`;
interface PanelProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}
export const Panel = (props: PanelProps) => {
  const {
    children,
    ...rest
  } = props;
  return <PanelContainer {...rest}>{children}</PanelContainer>;
};