import { dbtTheme } from "../../../configs/dbtTheme";
export const AccountLoggedIn = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" fill={color} />
      <path d="M12 13.9805L18.2044 15.7039C20.1521 16.2449 21.5 18.0183 21.5 20.0397V21.9994L3 22L2.5 21.9994V20.0397C2.5 18.0183 3.84791 16.2449 5.7956 15.7039L12 13.9805Z" fill={color} />
    </svg>;
};