import { buildUrl, getOrThrowResponse } from "@dbt/commons/utils";
export const ApiRoutes = {
  getUnabalancedAccounts: (orgId: string) => buildUrl("api/portal/get-unbalanced-accounts", {
    orgId
  }),
  deleteErpConnection: (erpCompanyId?: string | null) => buildUrl("api/portal/accounting", {
    erpCompanyId
  })
};
export async function deleteErp(erpCompanyId?: string | null): Promise<any> {
  const response = await fetch(ApiRoutes.deleteErpConnection(erpCompanyId), {
    method: "DELETE"
  });
  return getOrThrowResponse(response);
}
export async function getUnabalancedAccounts(orgId: string) {
  const response = await fetch(ApiRoutes.getUnabalancedAccounts(orgId), {
    method: "GET"
  });
  return getOrThrowResponse(response);
}