import { createContext, PropsWithChildren, useCallback, useMemo, useState } from "react";
export interface ModalState {
  showModal(id: string): void;
  hideModal(id: string): void;
  modalId?: string;
}
export const ModalContext = createContext<ModalState>(({} as ModalState));
export const ModalProvider = ({
  children
}: PropsWithChildren) => {
  const [modalQueue, setModalQueue] = useState<string[]>([]);
  const showModal = useCallback((id: string) => {
    setModalQueue(state => [id, ...state]);
  }, []);
  const hideModal = useCallback((id: string) => {
    setModalQueue(state => state.filter(mId => mId !== id));
  }, []);
  const modalId = useMemo(() => modalQueue[0], [modalQueue]);
  const value = useMemo(() => ({
    showModal,
    hideModal,
    modalId
  }), [hideModal, modalId, showModal]);
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};