import { erpName } from "@dbt/commons/components/erpConnection";
import { useUnmount } from "@dbt/commons/hooks";
import { ApiRoutes, getActuals, getCounterparty } from "api/client/counterparties";
import { useActuals, useCounterparty } from "hooks/api";
import { useUser } from "hooks/contexts";
import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { defer, from, repeat, Subscription, takeUntil, takeWhile } from "rxjs";
import { useSWRConfig } from "swr";
import { FortnoxState } from "types/fortnox";
import { getYears } from "utils/actuals";
import { SieEventType, trackSie } from "utils/gtm";
import { getMontoConnectUrl } from "api/client/erp";
import { BroadcastChannel } from "broadcast-channel";
import { MontoIntegration } from "@dbt/types/monto";
export const fortnoxChannelId = "fortnox-channel";
interface ErpConnectContextState {
  handleConnectErp(): void;
  cancelSubscription(): void;
  isProcessingErpPolling: boolean;
  isProcessingActualsPolling: boolean;
  fortnoxStatus: FortnoxState;
}
export const ErpConnectContext = createContext<ErpConnectContextState>(({} as ErpConnectContextState));
export const ErpConnectProvider = ({
  children
}: PropsWithChildren) => {
  const {
    fromDate,
    toDate
  } = getYears();
  const {
    currentRepresentation
  } = useUser();
  const {
    data: counterparty
  } = useCounterparty();
  const {
    data: actuals
  } = useActuals(3);
  const counterpartySubscription = useRef<Subscription>();
  const actualsSubscription = useRef<Subscription>();
  const unmount$ = useUnmount();
  const orgId = currentRepresentation.orgId;
  const {
    mutate
  } = useSWRConfig();
  const [startPolling, setStartPolling] = useState(false);
  const [isProcessingErpPolling, setIsProcessingErpPolling] = useState(false);
  const [isProcessingActualsPolling, setIsProcessingActualsPolling] = useState(false);
  const [fortnoxStatus, setFortnoxStatus] = useState<FortnoxState>(FortnoxState.LOADING);

  // Forcing to reset fortnox state to initial when currentRepresentation changes
  // Thats because state persists when switching company and that would show e.g. error message on other company
  useEffect(() => {
    if (currentRepresentation) {
      setFortnoxStatus(FortnoxState.LOADING);
    }
  }, [currentRepresentation]);
  const cancelSubscription = useCallback(() => {
    counterpartySubscription.current?.unsubscribe();
    actualsSubscription.current?.unsubscribe();
    setIsProcessingErpPolling(false);
    setIsProcessingActualsPolling(false);
  }, []);
  const pollCounterparty = useCallback((DELAY = 1000) => {
    if (counterpartySubscription.current) {
      cancelSubscription();
    }
    counterpartySubscription.current = defer(() => getCounterparty(currentRepresentation.orgId)).pipe(repeat({
      delay: DELAY,
      count: 30
    }),
    // cancel after max 30 seconds
    takeWhile(counterparty => !counterparty?.connectedErp || counterparty.connectedErp === erpName.manualSie, true), takeUntil(unmount$)).subscribe({
      next: counterparty => {
        setIsProcessingErpPolling(true);
        mutate(ApiRoutes.getCounterparty(orgId), counterparty, {
          revalidate: false
        });
      },
      complete: () => {
        setIsProcessingErpPolling(false);
      }
    });
  }, [currentRepresentation.orgId, orgId, mutate, unmount$, cancelSubscription]);
  const pollActuals = useCallback((DELAY = 1000) => {
    if (actualsSubscription.current) {
      actualsSubscription.current.unsubscribe();
    }
    actualsSubscription.current = defer(() => getActuals(fromDate, toDate)).pipe(repeat({
      delay: DELAY,
      count: 30
    }),
    // cancel after max 30 seconds
    takeWhile(data => !data || !data.actuals || data.actuals.length === 0, true), takeUntil(unmount$)).subscribe({
      next: data => {
        setIsProcessingActualsPolling(true);
        mutate(ApiRoutes.getActuals(fromDate, toDate), data, {
          revalidate: false
        });
      },
      complete: () => {
        mutate([ApiRoutes.getActuals(fromDate, toDate), orgId]);
        setIsProcessingActualsPolling(false);
      }
    });
  }, [fromDate, toDate, mutate, orgId, unmount$]);
  useEffect(() => {
    if (startPolling) {
      pollCounterparty();
      pollActuals();
    }
  }, [startPolling, pollCounterparty, pollActuals]);
  useEffect(() => {
    if ((counterparty?.connectedErp === erpName.fortnox || counterparty?.connectedErp === erpName.monto) && !actuals) {
      setFortnoxStatus(FortnoxState.SUCCESS);
      setStartPolling(true);
    } else {
      // We set to syncing state, If,
      //  user has authorized it but,
      //    not yet the connection is established
      //    OR
      //    If the current status is with manuel sie
      if (!!counterparty?.erpAuthorizedAt && (!counterparty?.connectedErp || counterparty?.connectedErp === erpName.manualSie)) {
        setFortnoxStatus(FortnoxState.SYNCING);
      } else {
        // set to initial state in case user decides to remove the connection while processing
        // to avoid showing success message
        setFortnoxStatus(FortnoxState.LOADING);
      }
    }
  }, [counterparty?.connectedErp, counterparty?.erpAuthorizedAt, actuals]);
  useEffect(() => {
    const channel = new BroadcastChannel(fortnoxChannelId);
    channel.onmessage = e => {
      setFortnoxStatus(e);
      if (e === FortnoxState.SUCCESS) {
        if (!startPolling) {
          setStartPolling(true);
        }
      }
    };
  }, [startPolling]);
  const handleConnectErp = useCallback((erpType?: MontoIntegration) => {
    const windowReference = window.open();
    trackSie(SieEventType.AUTO);
    const erpSystem: MontoIntegration = erpType || "FORTNOX";
    from(getMontoConnectUrl(currentRepresentation.orgId, currentRepresentation.orgName, erpSystem)).pipe(takeUntil(unmount$)).subscribe({
      next: ({
        connectUrl
      }) => {
        if (windowReference) {
          windowReference.location = connectUrl;
        }
      }
    });
  }, [currentRepresentation.orgId, currentRepresentation.orgName, unmount$]);
  const value = useMemo(() => ({
    handleConnectErp,
    cancelSubscription,
    isProcessingErpPolling,
    isProcessingActualsPolling,
    fortnoxStatus
  }), [handleConnectErp, cancelSubscription, isProcessingErpPolling, isProcessingActualsPolling, fortnoxStatus]);
  return <ErpConnectContext.Provider value={value}>
      {children}
    </ErpConnectContext.Provider>;
};