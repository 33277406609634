import { dbtTheme } from "../../../configs/dbtTheme";
export const WarningTriangleOutline = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 5.00391V8.00391H7.5V5.00391H8.5Z" fill="#666666" />
      <path d="M8.75 9.50391V11.0039H7.25V9.50391H8.75Z" fill="#666666" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.88072 0.80715C8.50315 0.105951 7.49734 0.105949 7.11978 0.80715L0.956884 12.2525C0.599245 12.9167 1.07698 13.7266 1.83598 13.7266H14.1675C14.9314 13.7266 15.3989 12.9123 15.0436 12.2525L8.88072 0.80715ZM1.83736 12.7266L8.00025 1.28125L14.1631 12.7266H1.83736Z" fill={color} />
    </svg>;
};