import { buildUrl, getOrThrowResponse } from "@dbt/commons/utils";
import { Drawdown } from "@dbt/commons/types";
export const ApiRoutes = {
  createDrawdown: () => buildUrl("/api/portal/create-drawdown", {}),
  getDrawdown: (agreementId: string) => buildUrl("/api/portal/get-drawdown", {
    agreementId
  })
};
export async function getDrawdowns(agreementId: string): Promise<Drawdown[]> {
  const response = await fetch(ApiRoutes.getDrawdown(agreementId), {
    method: "GET"
  });
  return getOrThrowResponse(response);
}
export async function createDrawdown(agreementId: string, requestedAmount: number, requestedLoanPeriod: number, requestedGracePeriod: number): Promise<Drawdown> {
  const response = await fetch(ApiRoutes.createDrawdown(), {
    method: "POST",
    body: JSON.stringify({
      agreementId,
      requestedAmount,
      requestedLoanPeriod,
      requestedGracePeriod
    })
  });
  return getOrThrowResponse(response);
}