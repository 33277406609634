import { dbtTheme } from "../../../configs/dbtTheme";
export const CloseModal = ({
  color = dbtTheme.colors.tertiary02_67
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_12625_15167)">
        <circle cx="12" cy="12" r="12" fill="#F5F5F3" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2882 12.002L6.69539 16.5948L7.40249 17.3019L11.9953 12.7091L16.5948 17.3087L17.3019 16.6016L12.7024 12.002L17.302 7.40242L16.5949 6.69531L11.9953 11.2949L7.40242 6.70207L6.69531 7.40917L11.2882 12.002Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_12625_15167">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>;
};