import { dbtTheme } from "../../../configs/dbtTheme";
export const Link = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 5.5C4.61929 5.5 3.5 6.61929 3.5 8C3.5 9.38071 4.61929 10.5 6 10.5H7V11.5H6C4.067 11.5 2.5 9.933 2.5 8C2.5 6.067 4.067 4.5 6 4.5H7V5.5H6Z" fill={color} />
      <path d="M6 8C6 7.72386 6.22386 7.5 6.5 7.5H9.5C9.77614 7.5 10 7.72386 10 8C10 8.27614 9.77614 8.5 9.5 8.5H6.5C6.22386 8.5 6 8.27614 6 8Z" fill={color} />
      <path d="M12.5 8C12.5 6.61929 11.3807 5.5 10 5.5H9V4.5H10C11.933 4.5 13.5 6.067 13.5 8C13.5 9.933 11.933 11.5 10 11.5H9V10.5H10C11.3807 10.5 12.5 9.38071 12.5 8Z" fill={color} />
    </svg>;
};