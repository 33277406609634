import _styled from "@emotion/styled";
import { dbtTheme } from "../../configs/dbtTheme";
import { MenuItem } from "./Navbar";
import { PadlockClosed } from "../icons/24px";
import { Badge } from "./Badge";
const StyledNavbarItem = _styled.button<{
  active: boolean;
  disabled: boolean;
}>`
  display: flex;
  padding: 12px;
  width: 100%;
  height: 37px;
  align-items: center;
  justify-content: space-between;
  font-family: ${dbtTheme.fontFamily.normal};
  :focus {
    outline: 2px solid ${dbtTheme.colors.primary.light};
  }
  background-color: ${({
  active
}) => active ? dbtTheme.colors.background.page : dbtTheme.colors.background.light};
  cursor: pointer;
  border-radius: 8px;

  ${({
  disabled
}) => disabled && `
    color: #C5C1B9;
    cursor: not-allowed;
    pointer-events: none;
    `}
`;
const LeftElements = _styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const IconContainer = _styled.div<{
  disabled: boolean;
}>`
  ${({
  disabled
}) => disabled && `
    svg{
      opacity: 40%;
    }
  `}
`;
interface NavbarItemProps {
  item: MenuItem;
  onClick(e: MenuItem): void;
  notifications?: number;
  "data-testid"?: string;
}
export const NavbarItem = ({
  item,
  onClick,
  notifications,
  ...rest
}: NavbarItemProps) => {
  return <StyledNavbarItem disabled={item.disabled} id={item.id} active={item.active} onClick={() => onClick(item)} data-testid={rest["data-testid"]}>
      <LeftElements>
        {item.icon && <IconContainer disabled={item.disabled}>{item.icon}</IconContainer>}
        {item.label}
      </LeftElements>
      {item.disabled && <PadlockClosed />}
      {!item.disabled && notifications && notifications > 0 && <Badge notifications={notifications} />}
    </StyledNavbarItem>;
};