import { formatDistanceToNow as _formatDistanceToNow, differenceInDays } from "date-fns";
export const sekFormatterWithDecimals = new Intl.NumberFormat("sv-SE", {
  style: "currency",
  currency: "SEK",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
export const sekFormatter = new Intl.NumberFormat("sv-SE", {
  style: "currency",
  currency: "SEK",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});
export const percentFormatter = new Intl.NumberFormat("sv-SE", {
  style: "percent",
  minimumSignificantDigits: 2,
  maximumSignificantDigits: 3
});
export const thousandFormatter = new Intl.NumberFormat("sv-SE", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 3
});
export const decimalFormatter = new Intl.NumberFormat("sv-SE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 3
});
export const intFormatter = new Intl.NumberFormat("sv-SE", {
  maximumFractionDigits: 0
});
interface FormatSekOptions {
  hideCurrency?: boolean;
  customFormatter?: Intl.NumberFormat;
}
export function formatSek(value: number | null | undefined, options?: FormatSekOptions): string | null {
  options = options || {};
  const formatter = options?.customFormatter || sekFormatter;
  return value != null ? options.hideCurrency ? formatter.format(value).replace("kr", "").trim() : formatter.format(value) : null;
}
export function formatDecimal(value: number | null | undefined, customFormatter?: Intl.NumberFormat): string | null {
  return value != null ? (customFormatter || decimalFormatter).format(value) : null;
}
export function formatInt(value: number | null | undefined): string | null {
  return value != null ? intFormatter.format(value) : null;
}
export function formatPercent(value: number | null | undefined, customFormatter?: Intl.NumberFormat): string | null {
  const formatter = customFormatter ? customFormatter : percentFormatter;
  return value != null ? formatter.format(value) : null;
}
export function formatMillionSek(value: number) {
  const valueInMillions = value / 1_000_000;
  const formatter = new Intl.NumberFormat("sv-SE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return `${formatter.format(valueInMillions)} MSEK`;
}
export const shortMonthName = (value: number): string => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];
  return months[value];
};
export const formatDueDate = (value: string) => new Date(value).toLocaleDateString(undefined, {
  year: "numeric",
  month: "long",
  day: "numeric"
});
export const formatDate = (date: Date) => Intl.DateTimeFormat("SV-se", {
  dateStyle: "short"
}).format(date);
export const formatDateTime = (date: Date, hour12 = false) => Intl.DateTimeFormat("SV-se", {
  dateStyle: "short",
  timeStyle: "short",
  hour12
}).format(date);
export const formatPortalDates = (date: Date) => Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
}).format(date);
export const toProperCase = (text: string) => {
  if (text) {
    return text.split(" ").map(name => name.charAt(0).toUpperCase() + name.substring(1).toLowerCase()).join(" ");
  }
  return text;
};
export function formatDistanceToNowInDays(date: Date | undefined) {
  if (date) {
    const days = differenceInDays(Date.now(), date);
    return `${days}d`;
  } else {
    return "";
  }
}
export function formatDistanceToNow(date: Date | undefined) {
  if (date) {
    return _formatDistanceToNow(date, {
      addSuffix: true,
      includeSeconds: false
    });
  } else {
    return "";
  }
}
export function formatFinancialYearPeriod(periods: string[] | null) {
  return periods && periods.length ? periods[periods.length - 1] : "";
}
export function listFormatter(stringArr: string[], language: "en-GB" | "sv-SE", type: "conjunction" | "disjunction") {
  const listFormatter = new Intl.ListFormat(language, {
    style: "long",
    type
  });
  return listFormatter.format(stringArr);
}