import _styled from "@emotion/styled";
import React from "react";
import { Navbar } from "@dbt/commons/components/navbar";
import { useMenu } from "hooks/contexts";
const StickyNavWrapper = _styled.div`
  width: 100%;
  position: sticky;
  top: 72px;
`;
export const SideNav = () => {
  const {
    menuItems,
    onItemClick
  } = useMenu();
  const visibleMenuItems = menuItems.filter(m => m.visible);
  return <StickyNavWrapper data-testid="sideNavbarSection">
      <Navbar onClick={item => onItemClick(item.path)} navbarItems={visibleMenuItems} />
    </StickyNavWrapper>;
};