import _styled from "@emotion/styled";
import { useCurrentBreakpoint } from "@dbt/commons/hooks";
import { CpRepresentationRole } from "@dbt/commons/types";
import { Text } from "@dbt/commons/components/text";
import { useCallback } from "react";
import { Checkmark } from "@dbt/commons/components/icons/24px";
import { dbtTheme } from "@dbt/commons/configs/dbtTheme";
import { triggerWithEnterOrSpaceKey } from "@dbt/commons/utils/dom_events";
import { useTranslation } from "next-i18next";
import { formatOrgNumber } from "@dbt/utils/formatting";
interface CompanyRowProps {
  representation: CpRepresentationRole;
  selected: boolean;
  "data-cy": string;
  onClick(orgId: string): void;
  tabIndex?: number;
}
const StyledCompanySelectBox = _styled.div<{
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 4px;

  width: 100%;

  ${({
  selected
}) => selected ? `border: 1px solid #009B37;` : `border: 1px solid rgba(60, 50, 20, 0.25);`}

  border-radius: 12px;
  cursor: pointer;
`;
const TextRow = _styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
`;
export function CompanyRow({
  representation,
  onClick,
  tabIndex,
  ...props
}: CompanyRowProps) {
  const handleClick = useCallback(() => {
    onClick(representation.orgId);
  }, [onClick, representation.orgId]);
  const layoutState = useCurrentBreakpoint();
  const {
    t
  } = useTranslation("Common");
  return <StyledCompanySelectBox {...layoutState} {...props} tabIndex={tabIndex} onClick={handleClick} onKeyPress={triggerWithEnterOrSpaceKey(handleClick)} key={representation.orgId}>
      <TextRow>
        <Text style={{
        fontWeight: "bold"
      }}>{representation.orgName}</Text>
        {props.selected && <Checkmark color={dbtTheme.colors.primary01_100} />}
      </TextRow>
      <Text>
        {t("orgNumber", {
        orgId: formatOrgNumber(representation.orgId)
      })}
      </Text>
    </StyledCompanySelectBox>;
}