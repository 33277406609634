import _styled from "@emotion/styled";
import { PropsWithChildren } from "react";
import { dbtTheme } from "../../configs/dbtTheme";
import { Loader as LoaderIcon24 } from "../icons/24px/Loader";
type ButtonVariant = "primary" | "secondary" | "tertiary";
type ButtonSize = "lg" | "md";
type ButtonColor = "positive" | "negative";
type ButtonSizes = { [key in ButtonSize]: string };
type ButtonVariants = { [key in ButtonVariant]: string };
type ButtonVariantsXColorsXStates = { [key in ButtonVariant]: { [key in ButtonColor]: (props: ButtonProps) => string } };
type ButtonLoadingPosition = "left" | "right";
export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, React.AriaAttributes {
  children?: React.ReactNode;
  variant: ButtonVariant;
  size: ButtonSize;
  color: ButtonColor;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  loading?: boolean;
  loadingText?: string;
  loadingPosition?: ButtonLoadingPosition;
  disabled?: boolean;
  underlined?: boolean;
  fullWidth?: boolean;
  noFocusOutlineOffset?: boolean;
}
const buttonSizesStyles: ButtonSizes = {
  lg: `
    height: 48px;
    padding: 12.5px 24px 12.5px 24px;
    border-radius: 50px;
  `,
  md: `
    height: 40px;
    padding: 8.5px 20px 8.5px 20px;
    border-radius: 50px;
  `
};
const buttonVariantsStyles: ButtonVariants = {
  primary: ``,
  secondary: ``,
  tertiary: `
    padding: 0px;
    height: auto;
    border-radius: 0px;
  `
};
const buttonVariantsXColorsXStatesStyles: ButtonVariantsXColorsXStates = {
  primary: {
    positive: ({
      disabled,
      loading
    }) => `
        color: ${dbtTheme.colors.tertiary01_100};
        background:  ${dbtTheme.colors.tertiary02_100};
        border: 1px solid ${dbtTheme.colors.tertiary02_100};

        &:hover {
          color: ${dbtTheme.colors.tertiary01_100};
          background: ${dbtTheme.colors.tertiary02_80};
          border-color: ${dbtTheme.colors.tertiary02_80};
        }

        &:active {
          color: ${dbtTheme.colors.tertiary01_100};
          background: ${dbtTheme.colors.tertiary02_130};
          border-color: ${dbtTheme.colors.tertiary02_130};
        }

        ${disabled ? `
                color:  ${dbtTheme.colors.tertiary01_100};
                background: ${dbtTheme.colors.tertiary02_25};
                border-color: ${dbtTheme.colors.tertiary02_25};
                pointer-events: none;
            ` : ""}

        ${loading ? `
                color:  ${dbtTheme.colors.tertiary01_100};
                background: ${dbtTheme.colors.tertiary02_100};
                border-color: ${dbtTheme.colors.tertiary02_100};
                pointer-events: none;
            ` : ""}
    `,
    negative: ({
      disabled,
      loading
    }) => `
        color: ${dbtTheme.colors.tertiary02_100};
        background: ${dbtTheme.colors.tertiary01_100};
        border: 1px solid ${dbtTheme.colors.tertiary01_100};

        &:hover {
          color: ${dbtTheme.colors.tertiary02_100};
          background: ${dbtTheme.colors.tertiary01_120};
          border-color: ${dbtTheme.colors.tertiary01_120};
        }

        &:active {
          color: ${dbtTheme.colors.tertiary02_100};
          background: ${dbtTheme.colors.tertiary01_140};
          border-color: ${dbtTheme.colors.tertiary01_140};
        }

        ${disabled ? `
                color:  ${dbtTheme.colors.tertiary02_100};
                background: ${dbtTheme.colors.tertiary01_165};
                border-color: ${dbtTheme.colors.tertiary01_165};
                pointer-events: none;
            ` : ""}

        ${loading ? `
                color:  ${dbtTheme.colors.tertiary02_100};
                background: ${dbtTheme.colors.tertiary01_100};
                border-color: ${dbtTheme.colors.tertiary01_100};
                pointer-events: none;
              ` : ""}
    `
  },
  secondary: {
    positive: ({
      disabled,
      loading
    }) => `
        color: ${dbtTheme.colors.tertiary02_67};
        background: transparent;
        border: 1px solid ${dbtTheme.colors.tertiary02_67};

        &:hover {
          color: ${dbtTheme.colors.tertiary01_100};
          background: ${dbtTheme.colors.tertiary02_80};
          border-color: ${dbtTheme.colors.tertiary02_80};
        }

        &:active {
          color: ${dbtTheme.colors.tertiary01_100};
          background: ${dbtTheme.colors.tertiary02_130};
          border-color: ${dbtTheme.colors.tertiary02_130};
        }

        ${disabled ? `
                color:  ${dbtTheme.colors.tertiary02_25};
                background: transparent;
                border-color: ${dbtTheme.colors.tertiary02_25};
                pointer-events: none;
              ` : ""}

        ${loading ? `
                color:  ${dbtTheme.colors.tertiary02_67};
                background: transparent;
                border-color: ${dbtTheme.colors.tertiary02_67};
                pointer-events: none;
            ` : ""}
    `,
    negative: ({
      disabled,
      loading
    }) => `
        color: ${dbtTheme.colors.tertiary01_100};
        background: transparent;
        border: 1px solid ${dbtTheme.colors.tertiary01_100};

        &:hover {
          color: ${dbtTheme.colors.tertiary02_100};
          background: ${dbtTheme.colors.tertiary01_120};
          border-color: ${dbtTheme.colors.tertiary01_120};
        }

        &:active {
          color: ${dbtTheme.colors.tertiary02_100};
          background: ${dbtTheme.colors.tertiary01_140};
          border-color: ${dbtTheme.colors.tertiary01_140};
        }

        ${disabled ? `
                color:  ${dbtTheme.colors.tertiary02_25};
                background: transparent;
                border-color: ${dbtTheme.colors.tertiary02_25};
                pointer-events: none;
            ` : ""}

        ${loading ? `
                color:  ${dbtTheme.colors.tertiary01_100};
                background: transparent;
                border-color: ${dbtTheme.colors.tertiary01_100};
                pointer-events: none;
           ` : ""}
    `
  },
  tertiary: {
    positive: ({
      disabled,
      loading,
      underlined
    }) => `
        color: ${dbtTheme.colors.tertiary02_100};
        background: transparent;
        border: none;
        text-decoration: ${underlined ? "underline" : "none"};

        &:hover {
          color: ${dbtTheme.colors.tertiary02_67};
        }

        &:active {
          color: ${dbtTheme.colors.tertiary02_100};
        }

        ${disabled ? `
                color:  ${dbtTheme.colors.tertiary02_25};
                pointer-events: none;
            ` : ""}

        ${loading ? `
                color:  ${dbtTheme.colors.tertiary02_100};
                pointer-events: none;
            ` : ""}
    `,
    negative: ({
      disabled,
      loading,
      underlined
    }) => `
        color: ${dbtTheme.colors.tertiary01_100};
        background: transparent;
        border: none;
        text-decoration: ${underlined ? "underline" : "none"};


        &:hover {
          color: ${dbtTheme.colors.tertiary01_120};
        }

        &:active {
          color: ${dbtTheme.colors.tertiary01_140};
        }

        ${disabled ? `
                color:  ${dbtTheme.colors.tertiary02_25};
                pointer-events: none;
            ` : ""}

        ${loading ? `
                color:  ${dbtTheme.colors.tertiary01_100};
                pointer-events: none;
            ` : ""}
    `
  }
};
const StyledButton = _styled.button<ButtonProps>`
  // Overall Styles
  position: relative;
  overflow: hidden;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 8px 20px;
  border-radius: 99px;
  letter-spacing: 0.32px;
  cursor: pointer;
  font-family: ${dbtTheme.fontFamily.normal};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  width: ${({
  fullWidth
}) => fullWidth ? "100%" : "auto"};

  // Focus Styles
  :focus,
  :active:focus {
    outline: 2px solid ${dbtTheme.colors.primary01_100};
    outline-offset: ${({
  noFocusOutlineOffset
}) => noFocusOutlineOffset ? "0px" : "2px"};
    box-shadow: none;
  }

  // Icon Style
  svg path {
    fill: currentColor; // Making icon has relevant same color in different states.
  }

  // Special Styles Based on Certain Props
  ${({
  size
}) => buttonSizesStyles[size]}
  ${({
  variant
}) => buttonVariantsStyles[variant]}
  ${({
  variant,
  color,
  ...props
}) => {
  return buttonVariantsXColorsXStatesStyles[variant]?.[color]({
    variant,
    color,
    ...props
  });
}}
`;
export const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    children,
    variant = "primary",
    size = "md",
    color = "positive",
    leftIcon,
    rightIcon,
    loading = false,
    loadingText,
    loadingPosition = "right",
    disabled = false,
    underlined = false,
    fullWidth = false,
    noFocusOutlineOffset = false,
    ...rest
  } = props;
  return <StyledButton variant={variant} size={size} color={color} loading={loading} disabled={disabled} underlined={underlined} fullWidth={fullWidth} noFocusOutlineOffset={noFocusOutlineOffset} {...rest}>
      {loading && loadingPosition === "left" && <LoaderIcon24 />}
      {!loading && leftIcon}
      {loading ? loadingText || children : children}
      {loading && loadingPosition === "right" && <LoaderIcon24 />}
      {!loading && rightIcon}
    </StyledButton>;
};