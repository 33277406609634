import { createContext, PropsWithChildren, useMemo } from "react";
import omit from "lodash/omit";
import { useFiles } from "hooks/api";
import { DocumentRepositoryItem } from "@dbt/commons/types";
import { createDocumentList, DocumentsBySlot } from "@dbt/commons/utils";
import { Files } from "@dbt/commons/types/files";
import { slotIds as allSlotIds } from "@dbt/commons/utils/slots";
export interface DocumentState {
  loading: boolean;
  files: Files;
  documentList: DocumentsBySlot[];
}
export const DocumentContext = createContext<DocumentState>(({} as DocumentState));

// Getting slotIds that support in client portal.
export const slotIds = omit(allSlotIds, "uc");
export type SlotIdKey = keyof typeof slotIds;
export type SlotId = (typeof slotIds)[SlotIdKey];
const slotArrs = Object.values(slotIds).reduce<Files>((acc, curr) => {
  acc[curr] = [];
  return acc;
}, {});
export const createFileInfo = (document: DocumentRepositoryItem, slotId: string) => {
  const {
    documentId,
    versions
  } = document;
  const {
    originalFileName,
    createdByUser,
    createdByStaff,
    created,
    blobId
  } = versions[0];
  const createdBy = createdByStaff ? `${createdByStaff.firstName} ${createdByStaff.lastName}` : createdByUser?.userName;
  return {
    slotId,
    documentId,
    name: originalFileName,
    blobId,
    createdBy,
    createdAt: created.slice(0, 10)
  };
};
export const DocumentProvider = ({
  children
}: PropsWithChildren) => {
  const {
    data = [],
    loading
  } = useFiles();
  const files = useMemo(() => data.reduce((obj, f) => {
    obj[f.slotId] = f.documents.map(document => createFileInfo(document, f.slotId));
    return obj;
  }, slotArrs), [data]);
  const documentList = useMemo(() => createDocumentList(data), [data]);
  return <DocumentContext.Provider value={{
    loading,
    files,
    documentList
  }}>
      {children}
    </DocumentContext.Provider>;
};