import _styled from "@emotion/styled";
import { Modal } from "@dbt/commons/components/modal";
import { Text } from "@dbt/commons/components/text";
import { useAuth, useModal } from "hooks/contexts";
import { useCallback, useEffect, useRef, useState } from "react";
import { refreshSession } from "api/client/user";
import { useCurrentBreakpoint } from "@dbt/commons/hooks";
import { dbtTheme } from "@dbt/commons/configs/dbtTheme";
import { Button } from "@dbt/commons/components/button";
import { Link } from "@dbt/commons/components/link";
import { ArrowRight } from "@dbt/commons/components/icons/24px";
import { useTranslation } from "next-i18next";
type LayoutState = {
  isDesktop: boolean;
  isMobile: boolean;
};
const Container = _styled.div<LayoutState>`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  ${({
  isMobile
}) => isMobile && `
  width: 100vw;
  `}
`;
const StyledText = _styled(Text)`
  white-space: pre;
`;
const TextRow = _styled.div<LayoutState>`
  ${({
  isMobile
}) => isMobile && `
    max-width: 264px;
  `}
  ${({
  isDesktop
}) => isDesktop && `
    display: flex;
    flex-direction: row;`}
`;
const ButtonRow = _styled.div<LayoutState>`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 16px;
  ${({
  isMobile
}) => isMobile && `
    width: 100%;
  flex-direction: column;
`}
  ${({
  isDesktop
}) => isDesktop && `
  flex-direction: row;
  justify-content: center;
  width: 100%;
`}
`;
const StyledButton = _styled(Button)<LayoutState>`
  ${({
  isMobile
}) => isMobile && `
  width: 100%;
  display: flex;
  justify-content: center !important;
`}
`;
export const sessionModalId = "session-modal";
export const SessionModal = () => {
  const {
    sessionData,
    logout
  } = useAuth();
  const layoutState = useCurrentBreakpoint();
  const {
    showModal,
    modalId,
    hideModal
  } = useModal();
  const [sessionExpire, setSessionExpire] = useState(sessionData?.userSessionExpire ?? null);
  const [time, setTime] = useState(Date.now());
  const interval = useRef<NodeJS.Timeout>();
  const stopTimer = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  }, []);
  useEffect(() => {
    if (sessionExpire === null) {
      return;
    }
    if (interval.current) {
      stopTimer();
    }
    interval.current = setInterval(() => {
      const sessionWillExpire = sessionExpire - Date.now() < 60000;
      const sessionHasExpired = sessionExpire - Date.now() < 0;
      setTime(Date.now());
      if (sessionWillExpire) {
        if (modalId !== sessionModalId) {
          showModal(sessionModalId);
        }
      }
      if (sessionHasExpired) {
        hideModal(sessionModalId);
        stopTimer();
        logout();
      }
    }, 1000);
    return stopTimer;
  }, [sessionExpire, modalId, stopTimer, showModal, hideModal, logout]);
  const handleClick = useCallback(() => {
    refreshSession().then(({
      expire
    }) => {
      setSessionExpire(expire);
      hideModal(sessionModalId);
      stopTimer();
    });
  }, [hideModal, stopTimer]);
  const seconds = sessionExpire ? Math.floor((sessionExpire - time) / 1000) : 0;
  const {
    t
  } = useTranslation("Common");
  return <Modal show={modalId === sessionModalId} data-testid="sessionModalSection">
      <Container {...layoutState}>
        {layoutState.isMobile && <TextRow {...layoutState}>
            <Text as="h5" font={"title_condensed"}>
              {t("logoutTitle")}
            </Text>
            <Text as="h5" font={"title_condensed"} color={dbtTheme.colors.primary.light}>
              {`${seconds} s`}
            </Text>
          </TextRow>}
        {layoutState.isDesktop && <TextRow {...layoutState}>
            <Text as="h5" font={"title_condensed"}>
              {t("logoutTitle")}
            </Text>
            <StyledText as="h5" font={"title_condensed"} color={dbtTheme.colors.primary.light}>
              {` ${seconds} s`}
            </StyledText>
          </TextRow>}
        <Text>{t("logoutInfo")}</Text>
        <ButtonRow {...layoutState}>
          <StyledButton {...layoutState} variant="primary" color="positive" size="md" onClick={logout} rightIcon={<ArrowRight />} data-testid="logoutButton">
            {t("logout")}
          </StyledButton>
          <Link onClick={handleClick} data-testid="stayLoggedInLink">
            {t("stayLoggedIn")}
          </Link>
        </ButtonRow>
      </Container>
    </Modal>;
};