import { dbtTheme } from "../../../configs/dbtTheme";
export const WithdrawMoney = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 4.5C2.5 3.67157 3.17157 3 4 3H20C20.8284 3 21.5 3.67157 21.5 4.5V7.14286H20.5V4.5C20.5 4.22386 20.2761 4 20 4H4C3.72386 4 3.5 4.22386 3.5 4.5V19.5C3.5 19.7761 3.72386 20 4 20H20C20.2761 20 20.5 19.7761 20.5 19.5V16.8571H21.5V19.5C21.5 20.3284 20.8284 21 20 21H4C3.17157 21 2.5 20.3284 2.5 19.5V4.5Z" fill={color} />
      <path d="M16.6544 7.64844L21.0079 12.002L16.6544 16.3555L15.9473 15.6484L19.0957 12.5H10V11.5H19.0917L15.9473 8.35554L16.6544 7.64844Z" fill={color} />
    </svg>;
};