export const MsDynamicsLogo = ({
  color = "#18264E"
}: {
  color?: string;
}) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="98" height="30" id="Layer_1" x="0px" y="0px" viewBox="0 0 983.33 307.99">
      {/* <style type="text/css"> .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#18264E;} </style>  */}
      <g>
        {" "}
        <polygon fillRule="evenodd" clipRule="evenodd" fill={color} points="0,98.96 0,307.99 191.44,204.08 191.44,108.95 8.12,298 61.74,138.99 "></polygon>{" "}
        <polygon fillRule="evenodd" clipRule="evenodd" fill={color} points="0,85.21 0,0 184.6,100.22 122.42,144.91 "></polygon>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M245.36,140.34v80.32h14.88c6.67,0,12.64-0.89,17.88-2.71c5.23-1.77,9.69-4.44,13.35-7.94 c3.67-3.5,6.46-7.8,8.33-12.92c1.94-5.13,2.89-11.02,2.89-17.67c0-26.1-13.84-39.13-41.48-39.13h-15.89v0.04H245.36z M236.39,228.88v-96.76h25.47c16.56,0,29.14,3.98,37.58,11.92c8.49,7.94,12.74,19.65,12.74,35.19c0,7.48-1.2,14.27-3.58,20.39 c-2.35,6.12-5.76,11.34-10.22,15.7c-4.45,4.35-9.84,7.69-16.24,10.04c-6.34,2.34-13.52,3.56-21.5,3.56h-24.28v-0.04H236.39z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M345.49,241.31c-2.73,6.76-5.83,11.77-9.34,15.03c-3.51,3.25-7.76,4.93-12.81,4.93c-2.47,0-4.58-0.32-6.32-0.9 v-8.11c1,0.37,1.99,0.62,3.01,0.78c0.99,0.16,2.06,0.25,3.1,0.25c2.84,0,5.32-0.9,7.41-2.68c2.08-1.77,3.93-4.45,5.46-8.06 l6.04-14.2l-26.69-68.86h9.62l19.87,54.78c0.25,0.74,0.53,1.52,0.78,2.47c0.25,0.95,0.47,1.84,0.75,2.72h0.32 c0.21-0.78,0.42-1.52,0.63-2.35c0.2-0.78,0.46-1.68,0.82-2.62l21.07-54.94h9.13L345.49,241.31z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M430.78,228.67v-39.84c0-15.59-5.62-23.44-16.96-23.44c-3.04,0-5.78,0.57-8.35,1.77 c-2.57,1.19-4.76,2.83-6.64,4.97c-1.88,2.09-3.33,4.61-4.38,7.53c-1.04,2.88-1.56,6.01-1.56,9.42v39.56h-8.61v-69.11h8.61v12.05 h0.25c5.12-9.1,12.83-13.66,23.04-13.66c7.51,0,13.29,2.47,17.21,7.37c3.97,4.93,5.95,11.98,5.95,21.26v42.12H430.78z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M474.77,195.23c-3.05,0.47-5.7,0.99-7.87,1.68c-2.17,0.69-3.99,1.56-5.38,2.72c-1.47,1.15-2.49,2.55-3.18,4.24 c-0.68,1.68-0.98,3.77-0.98,6.21c0,1.84,0.32,3.57,0.98,5.14c0.61,1.56,1.6,2.92,2.81,4.08c1.25,1.15,2.69,2.04,4.45,2.67 c1.75,0.62,3.74,0.95,5.96,0.95c3.01,0,5.82-0.58,8.34-1.78c2.54-1.15,4.72-2.76,6.6-4.85c1.87-2.1,3.31-4.56,4.35-7.44 c1.03-2.88,1.55-6.01,1.55-9.38v-6.79L474.77,195.23z M492.41,228.79v-12.3h-0.25c-2.23,4.4-5.29,7.85-9.12,10.25 c-3.83,2.42-8.19,3.67-13.06,3.67c-3.31,0-6.27-0.47-8.92-1.4c-2.65-0.95-4.93-2.31-6.73-4.04c-1.87-1.73-3.26-3.83-4.31-6.22 c-0.98-2.41-1.51-5.13-1.51-8.05c0-6.29,1.91-11.27,5.75-14.98c3.83-3.7,9.65-6.12,17.36-7.27l20.78-3.04 c0-13.28-5.08-19.94-15.28-19.94c-7.98,0-15.4,2.93-22.19,8.84v-9.47c1.46-1.04,3.05-1.97,4.88-2.83c1.82-0.82,3.74-1.56,5.7-2.14 c2.03-0.62,4.04-1.11,6.16-1.4c2.12-0.32,4.19-0.53,6.16-0.53c7.62,0,13.37,2.14,17.27,6.49c3.88,4.35,5.86,10.78,5.86,19.32v44.97 h-8.55v0.09H492.41z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M601.7,228.74v-40.67c0-4.14-0.32-7.64-0.95-10.52c-0.61-2.89-1.61-5.19-2.92-7.03 c-1.31-1.77-3.04-3.09-5.06-3.91c-2.04-0.79-4.48-1.2-7.32-1.2c-2.51,0-4.92,0.58-7.16,1.77c-2.25,1.2-4.24,2.83-5.91,4.93 c-1.68,2.1-3.04,4.56-3.98,7.37c-0.99,2.83-1.47,5.92-1.47,9.17v39.98h-8.62v-41.5c0-14.49-5.55-21.72-16.69-21.72 c-2.56,0-4.97,0.58-7.16,1.73c-2.18,1.15-4.14,2.76-5.76,4.82c-1.61,2.1-2.92,4.55-3.87,7.48c-0.94,2.88-1.35,6.12-1.35,9.62v39.61 h-8.63v-69.09h8.63v11.2h0.25c4.86-8.52,11.92-12.83,21.25-12.83c2.41,0,4.71,0.37,6.9,1.11c2.14,0.74,4.07,1.77,5.8,3.08 c1.72,1.32,3.19,2.93,4.44,4.77c1.26,1.84,2.14,3.87,2.83,6.08c2.41-4.93,5.59-8.64,9.46-11.2c3.86-2.55,8.26-3.87,13.19-3.87 c15.17,0,22.76,9.37,22.76,28.1v42.65h-8.62v0.07H601.7z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M624.09,159.72h8.55v68.96h-8.55V159.72z M628.35,142.28c-1.67,0-3.05-0.57-4.31-1.77 c-1.19-1.14-1.82-2.66-1.82-4.43c0-1.77,0.61-3.19,1.82-4.34c1.18-1.15,2.64-1.72,4.31-1.72c0.82,0,1.67,0.16,2.4,0.41 c0.77,0.32,1.4,0.74,1.97,1.26c0.57,0.53,0.97,1.19,1.34,1.93c0.32,0.74,0.53,1.56,0.53,2.46c0,0.82-0.16,1.68-0.46,2.41 c-0.32,0.78-0.78,1.4-1.35,1.97c-0.57,0.58-1.25,0.99-1.96,1.31C630.06,142.13,629.22,142.28,628.35,142.28"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M693.26,225.54c-5.11,3.09-11.28,4.72-18.51,4.82c-4.95,0.04-9.36-0.79-13.26-2.56 c-3.96-1.77-7.27-4.18-10.09-7.32c-2.75-3.13-4.9-6.8-6.41-11.11c-1.47-4.24-2.24-8.89-2.24-13.86c0-5.19,0.77-10.04,2.33-14.6 c1.55-4.56,3.84-8.52,6.86-11.93c3.03-3.4,6.66-6.08,11.04-8.06c4.33-1.97,9.31-2.99,14.87-2.99c5.68,0,10.87,1.11,15.55,3.29v9.17 c-4.99-3.33-10.4-4.97-16.23-4.97c-4.05,0-7.69,0.78-10.87,2.35c-3.18,1.56-5.83,3.66-8.01,6.33c-2.18,2.67-3.84,5.8-4.95,9.37 c-1.14,3.57-1.71,7.37-1.71,11.35c0,3.71,0.46,7.24,1.47,10.62c0.94,3.34,2.45,6.33,4.41,8.97c1.97,2.55,4.54,4.65,7.55,6.17 c3.02,1.52,6.62,2.31,10.71,2.31c6.46,0,12.27-1.94,17.42-5.81v8.48H693.26z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M699.74,216.33c1.32,1.04,2.73,1.94,4.26,2.76c1.53,0.78,3.11,1.52,4.75,2.1c1.62,0.58,3.26,1.04,4.96,1.32 c1.62,0.32,3.22,0.41,4.75,0.41c10.31,0,15.48-3.87,15.48-11.56c0-1.77-0.38-3.29-1.11-4.56c-0.75-1.31-1.78-2.47-3.11-3.5 c-1.37-1.04-3.01-2.04-4.89-2.92c-1.9-0.9-4.05-1.84-6.48-2.83c-3.22-1.32-6-2.62-8.27-3.98c-2.32-1.36-4.21-2.83-5.67-4.45 c-1.48-1.61-2.57-3.33-3.32-5.3c-0.74-1.96-1.11-4.07-1.11-6.49c0-3.04,0.7-5.8,1.99-8.18c1.32-2.41,3.15-4.4,5.42-6.08 c2.27-1.68,4.89-2.92,7.85-3.82c2.94-0.9,6.05-1.32,9.31-1.32c6,0,11.27,1.15,15.73,3.34v9.09c-5.21-3.33-10.84-4.97-16.84-4.97 c-2.16,0-4.18,0.25-5.96,0.79c-1.78,0.53-3.31,1.31-4.58,2.3c-1.28,0.99-2.27,2.14-2.95,3.57c-0.69,1.36-1.04,2.88-1.04,4.56 c0,1.73,0.25,3.2,0.79,4.49s1.37,2.47,2.53,3.45c1.16,1.04,2.64,2.05,4.47,3c1.86,0.94,4.05,1.93,6.69,3.04 c3.36,1.36,6.26,2.68,8.69,4.04c2.44,1.31,4.47,2.76,6.05,4.35c1.58,1.59,2.74,3.29,3.53,5.18c0.79,1.94,1.16,4.15,1.16,6.66 c0,3.09-0.62,5.87-1.95,8.32c-1.32,2.41-3.11,4.49-5.37,6.12c-2.27,1.68-4.96,2.92-8.06,3.77c-3.06,0.83-6.41,1.32-10.05,1.32 c-6.99,0-12.95-1.47-17.74-4.4v-9.63h0.11V216.33z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M787.53,225.18v-14.68c6.3,4.97,13.43,7.47,21.4,7.47c6.46,0,11.58-1.47,15.46-4.39 c3.88-2.92,5.77-6.9,5.77-11.85c0-10.96-8.41-16.45-25.22-16.45h-7.55v-12.52h7.19c14.95,0,22.38-5.17,22.38-15.5 c0-9.51-5.82-14.24-17.47-14.24c-6.51,0-12.58,2.3-18.35,6.93v-13.87c6.61-3.69,14.38-5.54,23.34-5.54 c8.61,0,15.54,2.14,20.73,6.48c5.19,4.34,7.75,9.81,7.75,16.54c0,12.69-6.5,20.88-19.5,24.54v0.25c6.97,0.68,12.54,3.12,16.57,7.3 c4.04,4.18,6.1,9.44,6.1,15.71c0,8.66-3.25,15.67-9.81,21c-6.55,5.33-15.17,7.99-25.86,7.99 C800.89,230.4,793.25,228.68,787.53,225.18"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M903.16,199c0-2.75-0.32-5.33-0.99-7.62c-0.61-2.3-1.61-4.23-2.99-5.9c-1.32-1.61-3-2.91-4.98-3.82 c-1.98-0.9-4.35-1.35-7.07-1.35c-2.72,0-5.07,0.46-7.12,1.47c-2.05,0.98-3.78,2.25-5.19,3.81c-1.41,1.56-2.47,3.4-3.14,5.38 c-0.69,1.97-1.04,4.06-1.04,6.2c0,2.3,0.32,4.64,0.99,7.05c0.62,2.4,1.68,4.59,2.99,6.56c1.32,1.98,3.1,3.61,5.19,4.92 c2.1,1.31,4.56,1.93,7.49,1.93c2.55,0,4.82-0.47,6.8-1.4c1.98-0.94,3.61-2.25,4.97-3.9c1.32-1.68,2.35-3.61,3.04-5.9 C902.85,204.05,903.16,201.59,903.16,199 M918.98,197.69c0,4.84-0.78,9.28-2.42,13.26c-1.61,4.02-3.83,7.47-6.66,10.34 c-2.83,2.87-6.18,5.12-10.13,6.73c-3.87,1.61-8.12,2.41-12.68,2.41c-4.86,0-9.32-0.94-13.42-2.83c-4.08-1.89-7.61-4.71-10.54-8.41 c-2.93-3.7-5.24-8.36-6.87-13.88c-1.61-5.54-2.42-11.94-2.42-19.32c0-8.93,1.04-16.85,3.2-23.75c2.15-6.89,5.07-12.68,8.81-17.39 c3.71-4.64,8.12-8.2,13.21-10.66c5.02-2.41,10.47-3.65,16.35-3.65c3.66,0,6.96,0.25,9.96,0.74c2.99,0.46,5.4,1.15,7.17,2.04v14.1 c-2.31-1.15-4.77-2.13-7.38-2.98c-2.62-0.78-5.6-1.19-9.01-1.19c-3.78,0-7.29,0.78-10.47,2.3c-3.18,1.51-5.97,3.76-8.28,6.69 c-2.3,2.91-4.15,6.56-5.44,10.91c-1.31,4.34-2.05,9.35-2.05,14.98h0.44c2.11-3.19,4.87-5.69,8.44-7.51 c3.57-1.83,7.7-2.75,12.48-2.75c4.24,0,8.06,0.69,11.48,2.04c3.41,1.35,6.3,3.33,8.69,5.95c2.42,2.55,4.24,5.75,5.55,9.44 C918.36,188.91,918.98,193.02,918.98,197.69"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M925.98,226.49v-15.02c6.34,4.27,12.78,6.43,19.36,6.43c6.61,0,11.91-1.68,15.91-5.01 c4.04-3.33,6.02-7.79,6.02-13.37c0-5.48-2.05-9.76-6.13-12.79c-4.09-3.04-9.91-4.6-17.57-4.6c-2.63,0-7.92,0.25-15.91,0.78 l3.47-50.78h47.77v13.29h-34.71l-1.61,24.17c3.09-0.17,5.93-0.25,8.44-0.25c10.27,0,18.26,2.61,23.87,7.88 c5.61,5.28,8.44,12.42,8.44,21.57c0,9.56-3.2,17.23-9.64,23.02c-6.47,5.79-15.26,8.66-26.5,8.66 C937.68,230.46,930.59,229.16,925.98,226.49"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M284.46,119.21V83.45c0-1.85,0.16-4.78,0.41-8.88h-0.16c-0.32,1.11-0.62,2.06-0.94,2.84 c-0.25,0.79-0.53,1.48-0.78,1.95l-17.9,39.88h-2.04l-17.94-39.6c-0.53-1.2-1.04-2.89-1.61-5.08h-0.16c0.11,1.11,0.16,2.35,0.2,3.79 c0.04,1.43,0.04,3,0.04,4.74v36.2h-4.61v-51.9h5.61l17.42,38.97c0.37,0.83,0.73,1.68,1.03,2.52c0.37,0.83,0.69,1.78,0.94,2.73h0.21 c0.32-0.95,0.61-1.85,0.94-2.63c0.25-0.79,0.61-1.62,1.03-2.57l17.58-38.97h5.35v51.85h-4.69v-0.07H284.46z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M297.97,82.17h4.74v36.89h-4.74V82.17z M300.34,72.87c-0.92,0-1.73-0.32-2.37-0.95 c-0.64-0.61-1.02-1.4-1.02-2.34c0-0.95,0.33-1.73,1.02-2.31c0.64-0.62,1.45-0.94,2.37-0.94c0.47,0,0.92,0.04,1.35,0.2 c0.42,0.17,0.81,0.37,1.06,0.69c0.33,0.25,0.54,0.62,0.76,1.04c0.17,0.41,0.26,0.83,0.26,1.32c0,0.48-0.12,0.9-0.26,1.31 c-0.17,0.42-0.43,0.78-0.76,1.04c-0.32,0.32-0.71,0.53-1.06,0.69C301.26,72.82,300.84,72.87,300.34,72.87"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M335.08,117.27c-2.72,1.67-6.1,2.48-9.98,2.54c-2.62,0-5.03-0.41-7.12-1.35c-2.11-0.93-3.93-2.24-5.4-3.88 c-1.47-1.67-2.63-3.58-3.41-5.86c-0.79-2.24-1.2-4.68-1.2-7.3c0-2.74,0.41-5.29,1.27-7.73c0.85-2.45,2.05-4.52,3.67-6.33 c1.61-1.82,3.58-3.21,5.93-4.24c2.31-1.03,4.98-1.55,8.03-1.55c3.05,0,5.82,0.57,8.41,1.75v4.89c-2.68-1.76-5.61-2.65-8.75-2.65 c-2.19,0-4.16,0.41-5.82,1.25c-1.73,0.82-3.14,1.91-4.29,3.38c-1.16,1.39-2.05,3.05-2.68,4.97c-0.62,1.87-0.94,3.88-0.94,6.02 c0,1.97,0.25,3.83,0.78,5.59c0.54,1.75,1.32,3.38,2.35,4.72c1.04,1.35,2.43,2.44,4.04,3.26s3.58,1.18,5.76,1.18 c3.47,0,6.61-1.03,9.4-3.05L335.08,117.27z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M357.61,87.01c-1.1-0.78-2.44-1.19-4.15-1.19c-1.18,0-2.28,0.21-3.22,0.69c-0.93,0.46-1.75,1.1-2.44,1.88 c-0.73,0.78-1.3,1.72-1.75,2.75c-0.46,1.04-0.89,2.13-1.18,3.23c-0.32,1.15-0.53,2.25-0.62,3.4c-0.16,1.14-0.2,2.25-0.2,3.27V119 h-4.52V82.27h4.52v7.86h0.16c0.41-1.35,0.98-2.54,1.6-3.6c0.68-1.03,1.39-1.97,2.28-2.7c0.89-0.74,1.82-1.31,2.81-1.68 c0.98-0.36,2.08-0.57,3.22-0.57c0.73,0,1.39,0.04,1.96,0.16c0.57,0.11,1.1,0.25,1.6,0.41v4.83h-0.04V87.01z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M375.95,85.5c-2.05,0-3.83,0.36-5.5,1.03c-1.61,0.68-3.04,1.71-4.19,3.01s-2.05,2.97-2.68,4.88 c-0.61,1.92-0.94,4.11-0.94,6.53c0,2.28,0.32,4.35,0.94,6.16c0.62,1.83,1.53,3.42,2.68,4.72c1.15,1.3,2.51,2.32,4.14,3.01 c1.61,0.73,3.46,1.03,5.51,1.03c2.04,0,3.87-0.36,5.5-1.03c1.57-0.68,2.92-1.67,4.04-2.97c1.11-1.3,1.93-2.9,2.51-4.76 c0.57-1.87,0.82-4.04,0.82-6.43c0-4.93-1.11-8.72-3.29-11.29C383.23,86.8,380.03,85.5,375.95,85.5 M375.58,119.81 c-2.72,0-5.19-0.46-7.37-1.34c-2.18-0.89-4.03-2.17-5.55-3.83c-1.52-1.67-2.71-3.63-3.5-5.96c-0.82-2.28-1.19-4.88-1.19-7.66 c0-3.06,0.41-5.76,1.31-8.19c0.9-2.4,2.1-4.45,3.71-6.12c1.57-1.66,3.5-2.97,5.76-3.83c2.26-0.89,4.77-1.31,7.54-1.31 c2.67,0,5.06,0.41,7.23,1.31c2.17,0.89,3.91,2.11,5.44,3.78c1.47,1.67,2.62,3.63,3.4,6.03c0.78,2.32,1.2,5.02,1.2,8.03 c0,2.85-0.42,5.45-1.27,7.77c-0.82,2.33-1.98,4.36-3.57,6.07c-1.52,1.71-3.4,3.01-5.6,3.95 C380.88,119.35,378.37,119.81,375.58,119.81"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M396.48,112.38c0.69,0.58,1.47,1.04,2.25,1.48c0.82,0.41,1.68,0.78,2.5,1.11c0.89,0.32,1.72,0.54,2.61,0.69 c0.9,0.16,1.73,0.21,2.51,0.21c5.49,0,8.2-2.06,8.2-6.19c0-0.95-0.21-1.74-0.58-2.43c-0.37-0.69-0.94-1.32-1.68-1.9 c-0.74-0.57-1.56-1.11-2.62-1.57c-0.98-0.46-2.13-0.99-3.4-1.52c-1.72-0.69-3.19-1.41-4.39-2.15c-1.19-0.75-2.25-1.53-3.04-2.36 c-0.78-0.83-1.35-1.78-1.76-2.84c-0.37-0.99-0.58-2.19-0.58-3.47c0-1.61,0.37-3.1,1.04-4.37c0.74-1.26,1.68-2.35,2.87-3.26 c1.2-0.9,2.55-1.57,4.14-2.05c1.58-0.48,3.24-0.69,4.96-0.69c3.19,0,5.95,0.58,8.36,1.78v4.87c-2.75-1.78-5.74-2.68-8.93-2.68 c-1.15,0-2.18,0.16-3.12,0.41c-0.95,0.32-1.77,0.69-2.41,1.2c-0.69,0.54-1.19,1.16-1.56,1.9c-0.37,0.74-0.58,1.53-0.58,2.42 c0,0.9,0.17,1.74,0.41,2.43c0.32,0.69,0.74,1.32,1.36,1.85c0.61,0.57,1.4,1.11,2.34,1.57c0.98,0.53,2.13,1.04,3.56,1.61 c1.76,0.74,3.28,1.41,4.59,2.15c1.32,0.74,2.34,1.48,3.19,2.31c0.85,0.83,1.47,1.74,1.89,2.77c0.41,1,0.62,2.19,0.62,3.58 c0,1.69-0.37,3.15-1.04,4.47c-0.69,1.32-1.61,2.42-2.83,3.3c-1.22,0.88-2.62,1.57-4.27,2.06c-1.61,0.46-3.4,0.69-5.33,0.69 c-3.69,0-6.84-0.79-9.39-2.36v-5.04H396.48z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M440.7,85.5c-2.05,0-3.83,0.36-5.5,1.03c-1.61,0.68-3.04,1.71-4.19,3.01s-2.05,2.97-2.68,4.88 c-0.61,1.92-0.94,4.11-0.94,6.53c0,2.28,0.32,4.35,0.94,6.16c0.62,1.83,1.53,3.42,2.68,4.72c1.15,1.3,2.51,2.32,4.14,3.01 c1.61,0.73,3.46,1.03,5.51,1.03c2.04,0,3.87-0.36,5.5-1.03c1.57-0.68,2.92-1.67,4.04-2.97c1.11-1.3,1.93-2.9,2.51-4.76 c0.57-1.87,0.82-4.04,0.82-6.43c0-4.93-1.11-8.72-3.29-11.29C447.91,86.8,444.78,85.5,440.7,85.5 M440.33,119.81 c-2.72,0-5.19-0.46-7.37-1.34c-2.18-0.89-4.03-2.17-5.55-3.83c-1.52-1.67-2.71-3.63-3.5-5.96c-0.82-2.28-1.19-4.88-1.19-7.66 c0-3.06,0.41-5.76,1.31-8.19c0.9-2.4,2.1-4.45,3.71-6.12c1.57-1.66,3.5-2.97,5.76-3.83c2.26-0.89,4.77-1.31,7.54-1.31 c2.67,0,5.06,0.41,7.23,1.31c2.17,0.89,3.91,2.11,5.44,3.78c1.47,1.67,2.62,3.63,3.4,6.03c0.78,2.32,1.2,5.02,1.2,8.03 c0,2.85-0.42,5.45-1.27,7.77c-0.82,2.33-1.98,4.36-3.57,6.07c-1.52,1.71-3.4,3.01-5.6,3.95 C445.61,119.35,443.1,119.81,440.33,119.81"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M480.18,68.75c-1.04-0.69-2.36-0.99-4.01-0.99c-4.38,0-6.53,2.84-6.53,8.52v5.95h9.22v4.06h-9.22v33.05h-4.63 V86.29h-6.48v-4.06h6.48v-6.2c0-1.85,0.21-3.52,0.69-5.05c0.47-1.53,1.16-2.77,2.11-3.89c0.9-1.04,2.06-1.9,3.47-2.48 c1.37-0.58,3.01-0.89,4.8-0.89c1.74,0,3.1,0.2,4.1,0.69V68.75z"></path>{" "}
        <path fillRule="evenodd" clipRule="evenodd" fill={color} d="M500.33,118.7c-1.57,0.78-3.32,1.19-5.21,1.19c-6,0-9.01-3.45-9.01-10.29V86.4h-6.53v-4.02h6.55V73.1 l4.63-1.52v10.75h9.59v4.03h-9.59v22.49c0,2.5,0.41,4.27,1.2,5.37c0.83,1.11,2.2,1.68,4.17,1.68c1.48,0,2.85-0.42,4.17-1.32v4.14 h0.03V118.7z"></path>{" "}
      </g>{" "}
    </svg>;
};