export const FortnoxLogo = ({
  color = "black"
}: {
  color?: string;
}) => {
  return <svg width="93" height="20" viewBox="0 0 93 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5645 0.563688H5.68953C2.29556 0.563688 0 2.94525 0 6.46638V19.1168C0 19.4753 0.197468 19.6802 0.543036 19.6802H3.24587C3.59144 19.6802 3.78891 19.4753 3.78891 19.1168V13.8543H10.5645C10.9101 13.8543 11.1075 13.6495 11.1075 13.291V10.6021C11.1075 10.2436 10.9101 10.0387 10.5645 10.0387H3.77657V6.37675C3.77657 5.19877 4.54175 4.41772 5.66485 4.41772H10.5522C10.8977 4.41772 11.0952 4.21286 11.0952 3.85434V1.13987C11.1075 0.768554 10.9101 0.563688 10.5645 0.563688Z" fill={color} />
      <path d="M19.8947 3.94366C15.4147 3.94366 12.1935 7.46479 12.1935 11.9718C12.1935 16.4789 15.427 20 19.8947 20C24.3624 20 27.596 16.4789 27.596 11.9718C27.596 7.46479 24.3748 3.94366 19.8947 3.94366ZM19.8947 16.2228C17.5991 16.2228 15.896 14.443 15.896 11.9718C15.896 9.50064 17.5745 7.72087 19.8947 7.72087C22.215 7.72087 23.8934 9.50064 23.8934 11.9718C23.8934 14.443 22.215 16.2228 19.8947 16.2228Z" fill={color} />
      <path d="M46.0476 4.22535H41.4318V0.56338C41.4318 0.204866 41.2343 0 40.8887 0H38.1736C37.828 0 37.6305 0.204866 37.6305 0.56338V4.22535H34.3723C31.003 4.22535 28.6828 6.60691 28.6828 10.128V19.1293C28.6828 19.4878 28.8802 19.6927 29.2258 19.6927H31.9286C32.2742 19.6927 32.4717 19.4878 32.4717 19.1293V10.0128C32.4717 8.83483 33.2369 8.05378 34.36 8.05378H37.6182V13.79C37.6182 17.3111 39.9138 19.6927 43.3077 19.6927H46.0106C46.3561 19.6927 46.5536 19.4878 46.5536 19.1293V16.4405C46.5536 16.0819 46.3561 15.8771 46.0106 15.8771H43.3201C42.1846 15.8771 41.4318 15.0832 41.4318 13.9181V8.05378H46.0476C46.3932 8.05378 46.5906 7.84891 46.5906 7.4904V4.80154C46.5906 4.40461 46.3932 4.22535 46.0476 4.22535Z" fill={color} />
      <path d="M54.4649 3.94366C50.3675 3.94366 47.6893 6.67094 47.6893 10.8323V19.1549C47.6893 19.5135 47.8868 19.7183 48.2324 19.7183H50.9352C51.2808 19.7183 51.4782 19.5135 51.4782 19.1549V10.8451C51.4782 8.96287 52.663 7.74648 54.4649 7.77209C56.2545 7.74648 57.4516 8.95007 57.4516 10.8451V19.1549C57.4516 19.5135 57.6491 19.7183 57.9947 19.7183H60.6975C61.0431 19.7183 61.2406 19.5135 61.2406 19.1549V10.8451C61.2529 6.67094 58.5624 3.94366 54.4649 3.94366Z" fill={color} />
      <path d="M70.0393 3.94366C65.5592 3.94366 62.338 7.46479 62.338 11.9718C62.338 16.4789 65.5716 20 70.0393 20C74.5193 20 77.7405 16.4789 77.7405 11.9718C77.7405 7.46479 74.4946 3.94366 70.0393 3.94366ZM70.0393 16.2228C67.7437 16.2228 66.0406 14.443 66.0406 11.9718C66.0406 9.50064 67.719 7.72087 70.0393 7.72087C72.3348 7.72087 74.038 9.50064 74.038 11.9718C74.038 14.443 72.3348 16.2228 70.0393 16.2228Z" fill={color} />
      <path d="M87.9594 11.9331L92.748 6.96513C93.0072 6.69625 93.0072 6.44017 92.748 6.17128L90.8721 4.21225C90.6129 3.94337 90.3661 3.94337 90.1069 4.21225L85.3183 9.18024L80.5297 4.21225C80.2705 3.94337 80.0237 3.94337 79.7645 4.21225L77.8762 6.17128C77.617 6.44017 77.617 6.69625 77.8762 6.96513L82.6648 11.9331L77.8268 16.9011C77.5676 17.17 77.5676 17.4261 77.8268 17.695L79.7151 19.654C79.9743 19.9229 80.2211 19.9229 80.4803 19.654L85.2689 14.686L90.0575 19.654C90.3167 19.9229 90.5635 19.9229 90.8227 19.654L92.711 17.695C92.9702 17.4261 92.9702 17.17 92.711 16.9011L87.9594 11.9331Z" fill={color} />
    </svg>;
};