import { createContext, PropsWithChildren, useCallback, useMemo, useState } from "react";
export interface ContactState {
  show(): void;
  hide(): void;
  toggle(): void;
  visible: boolean;
}
export const ContactContext = createContext<ContactState>(({} as ContactState));
export const ContactProvider = ({
  children
}: PropsWithChildren) => {
  const [visible, setVisible] = useState<boolean>(false);
  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);
  const toggle = useCallback(() => setVisible(state => !state), []);
  const value = useMemo(() => ({
    visible,
    show,
    hide,
    toggle
  }), [show, hide, visible, toggle]);
  return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>;
};