export type TBreakpointNames = "xs" | "sm" | "md" | "lg" | "xl" | "xl2";

// NOTE : If you are using tailwind values in the project too,
//        manually add/update that project's "tailwind.config.js --> theme.screens" object with below values. So they are in sync.
export const responsiveBreakpointsList = {
  xs: "360px",
  sm: "480px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xl2: "1400px"
};