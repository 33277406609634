export const GTM_ID = process.env.WEB_TRACKING_TAG;
export const handleRouteChange = () => {
  (window as any).dataLayer.push({
    event: "pageview"
  });
};
export enum SieEventType {
  MANUAL = "sie-manual",
  AUTO = "sie-auto",
}
export const trackSie = (event: SieEventType) => {
  (window as any).dataLayer.push({
    event: event
  });
};
export const trackMeetingBooked = () => {
  (window as any).dataLayer.push({
    event: "portal_form_meeting_confirmed"
  });
};