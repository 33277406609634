import { dbtTheme } from "../../../configs/dbtTheme";
export const GraphBar = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.5C12.8284 2.5 13.5 3.17157 13.5 4L13.5 20C13.5 20.8284 12.8284 21.5 12 21.5C11.1716 21.5 10.5 20.8284 10.5 20L10.5 4C10.5 3.17157 11.1716 2.5 12 2.5Z" fill={color} />
      <path d="M19 12.5C19.8284 12.5 20.5 13.1716 20.5 14V20C20.5 20.8284 19.8284 21.5 19 21.5C18.1716 21.5 17.5 20.8284 17.5 20V14C17.5 13.1716 18.1716 12.5 19 12.5Z" fill={color} />
      <path d="M6.5 10C6.5 9.17157 5.82843 8.5 5 8.5C4.17157 8.5 3.5 9.17157 3.5 10V20C3.5 20.8284 4.17157 21.5 5 21.5C5.82843 21.5 6.5 20.8284 6.5 20L6.5 10Z" fill={color} />
    </svg>;
};