import _styled from "@emotion/styled";
import { dbtTheme } from "../../configs/dbtTheme";
import { Link } from "../icons/24px";
import { Text } from "../text";
const InProgressBoxContainer = _styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px 4px 8px;
  gap: 4px;
  background: ${dbtTheme.colors.status.info_10};
  border-radius: 4px;
`;
interface InProgressBoxProps {
  "data-testid"?: string;
}
export const InProgressBox = (props: InProgressBoxProps) => {
  return <InProgressBoxContainer {...props}>
      <Link color={dbtTheme.colors.status.info_100} />
      <Text color={dbtTheme.colors.status.info_100}>{"Anslutning pågår"}</Text>
    </InProgressBoxContainer>;
};