import { dbtTheme } from "../../../configs/dbtTheme";
export const HamburgerMenu = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 6.5H4V5.5H20V6.5Z" fill={color} />
      <path d="M20 12.5H4V11.5H20V12.5Z" fill={color} />
      <path d="M4 18.5H20V17.5H4V18.5Z" fill={color} />
    </svg>;
};