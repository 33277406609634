import _styled from "@emotion/styled";
import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { useCurrentBreakpoint } from "@dbt/commons/hooks";
import { AppProps } from "AppRoot";
import { Background } from "@dbt/commons/components/background";
import { SelectCompanyModal } from "components/bankid/SelectCompanyModal";
import { NoActiveCompany } from "components/bankid/NoActiveCompany";
import { SideNav } from "components/sidenav";
import { ContactProvider } from "ContactProvider";
import { DocumentProvider } from "DocumentProvider";
import { useModal, useUser } from "hooks/contexts";
import { MenuProvider } from "MenuProvider";
import dynamic from "next/dynamic";
import RouteContainer from "RouteContainer";
import { dbtTheme } from "@dbt/commons/configs/dbtTheme";
import { ErpConnectProvider } from "ErpProvider";
import { envColorCode } from "@dbt/utils/general";
const OuterContainer = _styled.div({
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "flex-start"
});
const Container = _styled.div<{
  modalOpen: boolean;
}>`
  ${{
  "display": "flex",
  "height": "100%",
  "minHeight": "100vh",
  "width": "100%",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "space-between"
}}
  ${({
  modalOpen
}) => modalOpen && {
  "overflow": "hidden"
}}
`;
const SideNavContainer = _styled.nav<{
  isDesktop?: boolean;
  isMobile: boolean;
}>`
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${dbtTheme.colors.background.light};

  ${({
  isMobile
}) => isMobile && `
     display: none;
  `};
`;

// max-width 1652px: Greppan's initial max-width was 1920px including sidenavbar.
//                    1652px is the remaining page content width after subtracting
//                    sidenavbar 236px and padding 2 * 16px from greppans 1920px.

const PageContentContainer = _styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1652px;
`;
const Content = _styled.div<{
  isDesktop?: boolean;
  isMobile: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
  margin-top: 74px;
  padding-right: 16px;

  ${({
  isMobile
}) => isMobile && `
    max-width: 100vw;
    padding-left: 16px;
  `};
`;
const LayoutDesktop = dynamic(() => import("components/layout/LayoutDesktop"));
const LayoutMobile = dynamic(() => import("components/layout/LayoutMobile"));
const Footer = dynamic(() => import("components/footer/FooterDesktop"));
export default function LoggedIn(props: AppProps) {
  const {
    pageProps
  } = props;
  const router = useRouter();
  const {
    selectedCompanyId,
    multipleCompanies,
    user,
    currentRepresentation
  } = useUser();
  const {
    modalId
  } = useModal();
  const envColor = useMemo(() => envColorCode(), []);
  const layoutState = useCurrentBreakpoint();
  useEffect(() => {
    if (user) {
      Sentry.setTags({
        "user-name": user.userName,
        "user-email": user.email,
        "user-ssn": user.persNo,
        "user-authenticated": pageProps?.isAuthenticated,
        "user-selected-orgid": pageProps?.currentRepresentationOrgId,
        "client-route": router.pathname
      });
    }
    Sentry.setExtras({
      "Client Route": router.pathname,
      "Client Path": router.asPath,
      "Client Path Query Object": router.query
    });
  }, [user, router, pageProps]);

  // Handling multiple cases around current "selectedCompanyId" and available "Company list" to correctly show appropriate intermediate component and avoid runtime errors.
  //
  // By the way, case 2,3,4 scenario mostly happen when user has already selected a company(selectedCompanyId) which is saved in session data. But at some later
  // point, When he reload/change into another company, etc... that user no longer don't have access to that company.
  // This can happen when, An staff portal admin set the particular user/orgId to "removed" user status type at some point. Then that user don't have access to it.

  // Case1 Ex : A user is doing initial login and there are multiple other companies to select. (NORMAL SCENARIO)
  const case1 = !selectedCompanyId && !currentRepresentation && multipleCompanies;

  // Case2 Ex: A user is changing/reloading into not authorized company and there are other companies to select.
  const case2 = selectedCompanyId && !currentRepresentation && user.representations.length >= 1;

  // Case3 Ex : A user is doing initial login and there is no any other company to select.
  const case3 = !selectedCompanyId && !currentRepresentation && user.representations.length === 0;

  // Case4 Ex : A user is changing/reloading into not authorized company and there is no any other company to select.
  const case4 = selectedCompanyId && !currentRepresentation && user.representations.length === 0;
  if (case1 || case2) {
    return <Background envColor={envColor}>
        <SelectCompanyModal />
      </Background>;
  } else if (case3 || case4) {
    return <Background envColor={envColor}>
        <NoActiveCompany />
      </Background>;
  }

  // Came to here mean "selectedCompanyId" is a valid active company for the current user.
  return <MenuProvider>
      <ContactProvider>
        <DocumentProvider>
          <HubspotProvider>
            <ErpConnectProvider>
              <OuterContainer>
                <Container modalOpen={!!modalId}>
                  {layoutState.isDesktop ? <LayoutDesktop /> : <LayoutMobile />}
                  <Content {...layoutState}>
                    <SideNavContainer {...layoutState}>
                      <SideNav />
                    </SideNavContainer>
                    <PageContentContainer>
                      <RouteContainer {...props} />
                    </PageContentContainer>
                  </Content>
                  <Footer />
                </Container>
              </OuterContainer>
            </ErpConnectProvider>
          </HubspotProvider>
        </DocumentProvider>
      </ContactProvider>
    </MenuProvider>;
}