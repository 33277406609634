/**
 * Do not add a key and a color for 'production'. Because, non-existence of a color
 * means, we will hide the identification bar.
 */
const DEFAULT_COLORMAP: { [key: string]: string } = {
  sandbox: "cyan",
  sbxa: "#7bf50a",
  staging: "magenta",
  stage: "magenta",
  development: "#18ee11",
};

export const envColorCode = () => {
  const envClass =
    process.env.ENVIRONMENT_CLASS ||
    process.env.NEXT_PUBLIC_ENVIRONMENT_CLASS ||
    "unknown";

  return DEFAULT_COLORMAP[envClass as string];
};
