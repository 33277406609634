import _styled from "@emotion/styled";
import { MouseEventHandler, PropsWithChildren, useCallback } from "react";
import { dbtTheme, useCurrentBreakpoint } from "../../configs/dbtTheme";
interface ModalProps {
  show?: boolean;
  onBackdropClick?(): void;
}
const Container = _styled.div`
  ${{
  "position": "fixed",
  "left": "0px",
  "top": "0px",
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center",
  "--tw-bg-opacity": "0.3",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))"
}}
  z-index: 100;
`;
const Content = _styled.div<{
  isMobile?: boolean;
}>`
  border: 1px dashed rgba(60, 50, 20, 0.25);
  border-radius: 12px;
  display: flex;
  background: ${dbtTheme.colors.background.light};
  padding: 48px 40px;
  ${({
  isMobile
}) => isMobile && `
    max-width: calc(100vw - 48px);
    padding: 48px 24px;
  `}
`;
export const Modal = ({
  show,
  onBackdropClick,
  children,
  ...rest
}: PropsWithChildren<ModalProps>) => {
  const {
    isMobile
  } = useCurrentBreakpoint();
  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(event => {
    event.stopPropagation();
  }, []);
  return show ? <Container onClick={onBackdropClick} {...rest}>
      <Content onClick={handleClick} isMobile={isMobile}>
        {children}
      </Content>
    </Container> : null;
};