export const sekFormatter = new Intl.NumberFormat("sv-SE", {
  style: "currency",
  currency: "SEK",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const thousandFormatter = new Intl.NumberFormat("sv-SE", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
});

const decimalFormatter = (fractionDigits: number) =>
  new Intl.NumberFormat("sv-SE", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

interface FormatSekOptions {
  hideCurrency?: boolean;
  customFormatter?: Intl.NumberFormat;
}

const percentFormatter = (fractionDigits: number) =>
  new Intl.NumberFormat("sv-SE", {
    style: "percent",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const formatDecimal = (number: number, fractionDigits: number) =>
  decimalFormatter(fractionDigits).format(number);

export const formatPercentage = (number: number, fractionDigits: number) =>
  percentFormatter(fractionDigits).format(number);

export function formatSek(
  value: number | null | undefined,
  options?: FormatSekOptions
): string | null {
  options = options || {};
  const formatter = options?.customFormatter || sekFormatter;
  return value != null
    ? options.hideCurrency
      ? formatter.format(value).replace("kr", "").trim()
      : formatter.format(value)
    : null;
}

export function formatSEKk(amount: number) {
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedAmount = formatter.format(amount / 1_000);
  return `${formattedAmount} kSEK`;
}

export function formatThousandAsKWithSEKsuffix(amount: number) {
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedAmount = formatter.format(amount / 1_000);
  return `${formattedAmount} kSEK`;
}

export function formatMillionSek(value: number, fractionDigits: number = 2) {
  const valueInMillions = value / 1_000_000;
  const formatter = new Intl.NumberFormat("sv-SE", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return `${formatter.format(valueInMillions)} MSEK`;
}

export function formatMillionShort(value: number, fractionDigits: number = 1) {
  const valueInMillions = value / 1_000_000;
  const formatter = new Intl.NumberFormat("sv-SE", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return `${formatter.format(valueInMillions)}M`;
}

export function formatThousandsWithSpaceSeparator(value: number, decimals = 0) {
  const divisor = Math.pow(10, decimals);
  const roundedValue = Math.round((value / 1000) * divisor) / divisor;
  const formattedValue = roundedValue.toLocaleString("en-US", {
    minimumFractionDigits: decimals,
  });
  return formattedValue.replace(",", " ");
}

export function customFormatPercentage(
  value: number,
  options: Intl.NumberFormatOptions = {}
): string {
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: "percent",
    ...options,
  });

  return formatter.format(value);
}
