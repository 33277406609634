import _styled from "@emotion/styled";
import { PropsWithChildren } from "react";
const Wrapper = _styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const Container = _styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 24px;
  justify-content: center;
  align-items: center;
  background-color: white;
  background-image: url(/assets/images/bg-login.png);
  background-size: cover;
  background-repeat: repeat;
  background-position: left top;
`;
interface BackgroundProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  envColor?: string;
}
export const Background = ({
  children,
  envColor,
  ...props
}: BackgroundProps) => {
  return <Wrapper>
      {envColor && <div style={{
      width: "100%",
      height: "6px",
      backgroundColor: envColor
    }}></div>}
      <Container {...props}>{children}</Container>
    </Wrapper>;
};