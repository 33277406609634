import { TBreakpointNames, responsiveBreakpointsList } from "./constants";
import { useOrientation, IUseOrientationReturn } from "./useOrientation";
import { useWindowSize } from "./useWindowSize";
interface ICurrentBreakpointData extends IUseOrientationReturn {
  currentBreakpointName: null | TBreakpointNames;
  currentBreakpointWidth: null | string;
  currentBreakpointWidthAsInteger: null | number;
  isMobile: boolean;
  isDesktop: boolean;
}
export function useCurrentBreakpoint(): ICurrentBreakpointData {
  const {
    width
  } = useWindowSize();
  const orientationData = useOrientation();
  const breakpoints = Object.entries(responsiveBreakpointsList);
  let currentBreakpointData: ICurrentBreakpointData = {
    currentBreakpointName: null,
    currentBreakpointWidth: null,
    currentBreakpointWidthAsInteger: null,
    isMobile: false,
    isDesktop: true,
    ...orientationData
  };
  for (let i = 0; i < breakpoints.length; i++) {
    const breakpoint = breakpoints[i];
    const [breakpointName, breakpointPx] = breakpoint;
    const breakpointWidth = parseInt(breakpointPx);
    if (width <= breakpointWidth) {
      const isMobile = breakpointWidth <= parseInt(responsiveBreakpointsList.md);
      currentBreakpointData = {
        ...currentBreakpointData,
        currentBreakpointName: (breakpointName as TBreakpointNames),
        currentBreakpointWidth: breakpointPx,
        currentBreakpointWidthAsInteger: breakpointWidth,
        isMobile,
        isDesktop: !isMobile
      };
      break;
    }
  }
  return currentBreakpointData;
}