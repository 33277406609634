import { useDeviceData } from "./useDeviceData";
import { IUseOrientationReturn, useOrientation } from "./useOrientation";
export interface IUseMobileDeviceReturn extends IUseOrientationReturn {
  isMobileDevice: boolean;
  isTabletDevice: boolean;
  vendor: undefined | string;
  model: undefined | string;
  os: undefined | string;
  osVersion: undefined | string;
  browserName: undefined | string;
  browserVersion: undefined | string;
  browserEngine: undefined | string;
  browserEngineVersion: undefined | string;
}
export const useMobileDevice = function () {
  const deviceData = useDeviceData();
  const orientationData = useOrientation();
  let returnData: IUseMobileDeviceReturn = {
    isMobileDevice: false,
    isTabletDevice: false,
    vendor: undefined,
    model: undefined,
    os: undefined,
    osVersion: undefined,
    browserName: undefined,
    browserVersion: undefined,
    browserEngine: undefined,
    browserEngineVersion: undefined,
    ...orientationData
  };
  if (deviceData) {
    returnData = {
      ...returnData,
      isMobileDevice: deviceData.device.type === "mobile",
      isTabletDevice: deviceData.device.type === "tablet",
      vendor: deviceData.device.vendor,
      model: deviceData.device.model,
      os: deviceData.os.name,
      osVersion: deviceData.os.version,
      browserName: deviceData.browser.name,
      browserVersion: deviceData.browser.major,
      browserEngine: deviceData.engine.name,
      browserEngineVersion: deviceData.engine.version
    };
  }
  return returnData;
};