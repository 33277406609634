import { ContactContext } from "ContactProvider";
import { AuthContext } from "AuthProvider";
import { ModalContext } from "ModalProvider";
import { UserContext } from "UserProvider";
import { MenuContext } from "MenuProvider";
import { useContext } from "react";
import { DocumentContext } from "DocumentProvider";
import { DrawdownContext } from "DrawdownProvider";
import { DrawerContext } from "DrawerProvider";
import { ErpConnectContext } from "ErpProvider";
import { TemporaryUploadStateContext } from "TemporaryUploadState";
export const useAuth = () => useContext(AuthContext);
export const useContact = () => useContext(ContactContext);
export const useMenu = () => useContext(MenuContext);
export const useModal = () => useContext(ModalContext);
export const useDrawdownProvider = () => useContext(DrawdownContext);
export const useUser = () => useContext(UserContext);
export const useDocuments = () => useContext(DocumentContext);
export const useDrawer = () => useContext(DrawerContext);
export const useErpConnect = () => useContext(ErpConnectContext);
export const useTemporaryUploadState = () => useContext(TemporaryUploadStateContext);