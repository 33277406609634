const ORGID_REGEX = /^[A-Z]{2}-[0-9]{6}-[0-9]{4}/g;
const ORGID_REGEX_WO_COUNTRY = /^[0-9]{6}-[0-9]{4}/g;
const TECHNICAL_ORGID = /((XR|ZZ|CC)-)?(1000)[0-9]+/g;
const ANY_ID = /^[A-Z]{2}-[A-Z0-9]+/g;

export const isLooksLikeId = (id: string) => {
  return !!id.match(ANY_ID);
};

export const formatPersonNumber = <T extends string | null>(persNo: T): T => {
  if (!persNo) {
    return persNo;
  }

  let pId = persNo as string;
  if (persNo.match(ANY_ID)) {
    pId = persNo.substring(3);
  }

  if (pId.indexOf("-") >= 0) {
    return pId as T;
  }
  return (pId.slice(0, 8) + "-" + pId.slice(8)) as T;
};

export const formatOrgNumber = <T extends string | null | undefined>(
  orgNo: T,
  includeCountryPrefix = false,
): T => {
  if (!orgNo) {
    return orgNo;
  }

  if (orgNo.match(ORGID_REGEX)) {
    return includeCountryPrefix ? orgNo : (orgNo.substring(3) as T);
  } else if (orgNo.match(ORGID_REGEX_WO_COUNTRY)) {
    // TODO identify country based on number formatting
    return (includeCountryPrefix ? `SE-${orgNo}` : orgNo) as T;
  } else if (orgNo.match(TECHNICAL_ORGID)) {
    return orgNo.substring(3) as T; // we don't know the country, so return after removing prefix.
  }
  return orgNo;
};

const getFlagEmoji = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const getFlagEmojiFromId = (id: string) => {
  if (id.match(ANY_ID)) {
    return getFlagEmoji(id.substring(0, 2));
  }
  return getFlagEmoji("ZZ");
};
