export interface DBTError {
  error_details: string;
  message: string;
  statusCode: number;
}
export enum StaffPortalErrorCode {
  UnauthorizedError = "UnauthorizedError",
}
export enum SIEFileErrorCode {
  OrgNumberMismatchError = "OrgNumberMismatchError",
  OrgNumberMissingError = "OrgNumberMissingError",
  InvalidSieFileExtension = "InvalidSieFileExtension",
  InvalidFileExtension = "InvalidFileExtension",
  InvalidSIETypeError = "InvalidSIETypeError",
  CorruptedFileError = "CorruptedFileError",
  BalanceError = "BalanceError",
  GeneralFileUploadError = "GeneralFileUploadError",
  FortnoxIntegrationError = "FortnoxIntegrationError",
  VismaIntegrationError = "VismaIntegrationError",
}