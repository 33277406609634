import _styled from "@emotion/styled";
import { AppProps } from "AppRoot";
import React from "react";
const Container = _styled.div`
  ${() => ({
  "display": "flex",
  "height": "100%",
  "flexDirection": "column",
  "justifyContent": "space-between",
  "@media (min-width: 768px)": {
    "justifyContent": "flex-start"
  }
})}

  min-height: 100vh /* Fallback */
  min-height: 100dvh;
`;
export default function LoggedOut({
  Component,
  pageProps
}: AppProps) {
  return <Container>
      <Component {...pageProps} />
    </Container>;
}