import { dbtTheme } from "../../../configs/dbtTheme";
export const DBTLogo = ({
  color = dbtTheme.colors.tertiary02_100
}: {
  color?: string;
}) => {
  return <svg width="163" height="32" viewBox="0 0 875 173" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.26 0H0V0.55C6.39 3.47 9.67 11.13 9.67 17.69V119.09C9.67 127.29 6.39 137.18 0 140.1V140.65L65.45 140.42C96.82 140.42 125.09 117.81 125.09 69.48C125.09 27.54 103.02 0 63.26 0ZM88.37 125.31C80.63 134.8 65.51 135.3 63.15 135.33H46.14C37.57 135.33 33.19 128.4 33.19 117.09V21.7C33.19 10.76 36.84 5.1 45.04 5.1H62.86C79.71 5.1 87.95 18.1 88.74 19.4C95.36 31.1 98.82 49.61 98.82 73.49C98.82 99.19 95.23 115.83 88.37 125.3" fill={color} />
      <path d="M200.55 61.71C217.65 58.25 233.47 47.34 233.47 28.79C233.47 10.24 216.37 0.0599976 186.36 0.0599976H128.79V0.609998C135.34 3.52 138.43 9.7 138.43 18.07V122.34C138.43 130.71 135.34 137.07 128.79 139.98V140.65H188.55C222.93 140.65 241.11 124.64 241.11 102.27C241.11 78.81 221.1 65.53 200.55 61.71ZM161.68 18.6C161.68 9.89 165.67 4.98 173.1 4.96H188.74C200.92 4.96 209.11 15.15 209.11 31.52C209.11 48.8 200.2 58.98 184.37 58.98H161.69V18.61L161.68 18.6ZM190.41 135.19H174.78C166.23 135.19 161.68 130.28 161.68 121.55V64.62H184.36C204.19 64.62 215.28 76.8 215.28 98.45C215.28 120.1 206.02 135.17 190.4 135.19" fill={color} />
      <path d="M243.4 34.93H244.32C248.41 28.05 252.95 21.21 257.39 15.77C257.39 15.77 259.05 13.78 259.85 12.9C263.16 9.57 269.81 4.56 279.88 4.56H284.31C288.96 4.6 291.29 7.15 291.29 12.56V125.19C291.29 131.19 289.29 135.92 282.92 140.1V140.65H322.79V140.1C316.42 135.92 314.42 131.19 314.42 125.19V12.56C314.42 7.15 316.74 4.6 321.39 4.56H325.82C335.88 4.56 342.54 9.58 345.85 12.9C346.65 13.78 348.31 15.77 348.31 15.77C352.75 21.21 357.29 28.05 361.38 34.93H362.3V0.0599976H243.4V34.94V34.93Z" fill={color} />
      <path d="M374.61 70.41C374.61 29.81 400.1 0.350006 437.12 0.350006C470.17 0.350006 491.32 21.88 494.52 47.56H476.77C474.31 34.72 465.25 15.46 437.11 15.46C405.01 15.46 392.36 43.22 392.36 70.41C392.36 97.6 405.01 125.55 437.11 125.55C465.43 125.55 475.63 106.29 476.77 89.29H494.52C491.5 119.12 470.35 140.65 437.12 140.65C400.11 140.65 374.62 111.19 374.62 70.4" fill={color} />
      <path d="M498.48 113.19C498.48 91.1 514.72 85.05 539.65 80.52C556.08 77.5 563.63 75.61 563.63 66.17C563.63 58.62 559.48 52.01 543.61 52.01C525.29 52.01 520.01 57.49 519.06 71.46H503.2C504.14 52.95 515.85 38.79 544.18 38.79C562.12 38.79 578.36 46.34 578.36 69.95V118C578.36 125.55 579.49 129.33 583.27 129.33C583.84 129.33 584.4 129.33 585.53 129.14V139.71C583.08 140.28 580.81 140.66 578.17 140.66C569.86 140.66 564.76 135.66 563.82 123.76H563.44C557.21 134.15 546.26 140.57 530.21 140.57C510.76 140.57 498.49 129.43 498.49 113.19M531.72 127.35C549.85 127.35 563.63 119.23 563.63 100.72V85.43C560.8 88.45 551.35 90.91 540.97 92.98C522.28 96.75 514.34 101.29 514.34 112.43C514.34 121.87 519.25 127.35 531.71 127.35" fill={color} />
      <path d="M608.82 125.37H608.44V172.65H593.33V41.53H608.44V53.43H608.82C614.48 46.07 623.55 38.89 639.03 38.89C663.2 38.89 682.46 57.96 682.46 89.69C682.46 121.42 663.2 140.49 639.03 140.49C623.55 140.49 614.48 133.69 608.82 125.38M637.52 127.27C657.35 127.27 666.6 108.58 666.6 89.69C666.6 70.8 657.35 52.11 637.52 52.11C619.58 52.11 608.44 67.03 608.44 89.69C608.44 112.35 619.58 127.27 637.52 127.27Z" fill={color} />
      <path d="M765.52 113.14C765.54 91.05 781.78 85.01 806.71 80.5C823.14 77.49 830.69 75.61 830.7 66.17C830.7 58.62 826.56 52 810.69 51.99C792.37 51.98 787.08 57.45 786.13 71.42H770.27C771.23 52.9 782.94 38.75 811.27 38.77C829.21 38.78 845.44 46.35 845.43 69.95L845.4 118C845.4 125.55 846.53 129.33 850.3 129.33C850.87 129.33 851.43 129.33 852.57 129.14V139.72C850.11 140.28 847.84 140.66 845.2 140.66C836.89 140.66 831.8 135.66 830.86 123.76H830.48C824.24 134.14 813.28 140.55 797.24 140.54C777.79 140.53 765.52 129.38 765.53 113.14M798.79 127.34C816.92 127.34 830.7 119.22 830.7 100.71V85.42C827.87 88.44 818.42 90.9 808.04 92.97C789.35 96.75 781.41 101.28 781.41 112.42C781.41 121.86 786.32 127.34 798.78 127.34" fill={color} />
      <path d="M706.52 9.56H690.49V25.59H706.52V9.56Z" fill={color} />
      <path d="M706.07 41.81H690.96V140.65H706.07V41.81Z" fill={color} />
      <path d="M728.02 113.83V54.83H713.86V41.61H728.02V9.50999H743.13V41.61H762.01V54.83H743.13V114.59C743.13 124.79 745.59 127.43 754.08 127.43C757.29 127.43 758.8 127.24 762.01 126.86V139.7C757.67 140.46 755.4 140.65 752 140.65C737.84 140.65 728.02 135.74 728.02 113.83Z" fill={color} />
      <path d="M874.62 0.160004H859.51V140.68H874.62V0.160004Z" fill={color} />
    </svg>;
};