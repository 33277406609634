import _styled from "@emotion/styled";
import { css, cx } from "@emotion/css";
import { useTranslation } from "next-i18next";
import { useCurrentBreakpoint } from "@dbt/commons/hooks";
import { Button } from "@dbt/commons/components/button";
import { Text } from "@dbt/commons/components/text";
import { Link } from "@dbt/commons/components/link";
import { Panel } from "@dbt/commons/components/panel";
const panelDesktop = css`
  height: 600px;
  width: 581px;
`;
const center = css`
  text-align: center;
`;
const header = css`
  margin-bottom: 48px;
`;
const marginBottom = css`
  margin-bottom: 32px;
`;
const ContactInfo = _styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;
export const NoActiveCompany = () => {
  const {
    t
  } = useTranslation(["BankID", "Common"]);
  const {
    isDesktop
  } = useCurrentBreakpoint();
  return <Panel className={isDesktop ? cx(panelDesktop) : ""} data-testid="noActiveCompanySection">
      <Text className={cx(header, center)} as="h3" font={"title_condensed"}>
        {t("BankID:noActiveAccountTitle")}
      </Text>

      <Text className={cx(center)}>{t("BankID:noActiveAccount")}</Text>

      <ContactInfo className={cx(center, marginBottom)}>
        <span>
          {t("Common:call")}{" "}
          <Link href={`tel:${t("Common:dbtPhone")}`} data-testid="phoneLink">
            {t("Common:dbtPhone")}
          </Link>
        </span>
        <span>
          {t("Common:sendEmail")}{" "}
          <Link href={`mailto:${t("Common:dbtEmail")}`} data-testid="emailLink">
            {t("Common:dbtEmail")}
          </Link>
        </span>
      </ContactInfo>
      <Button variant="primary" color="positive" size="md" className={cx(marginBottom)} onClick={() => {
      window.location.href = "https://www.dbt.se/";
    }} data-testid="closeButton">
        {t("BankID:noActiveAccountCloseButton")}
      </Button>
    </Panel>;
};