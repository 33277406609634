import { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
export const FeatureFlagContext = createContext<string[]>([]);
export const FeatureFlagProvider = ({
  children
}: PropsWithChildren) => {
  const [features, setFeatures] = useState<string[]>([]);
  const processFeatures = useCallback(async () => {
    const res = await fetch("/api/features");
    const data = await res.json();
    setFeatures(data);
  }, []);
  useEffect(() => {
    processFeatures();
  }, [processFeatures]);
  return <FeatureFlagContext.Provider value={features}>
      {children}
    </FeatureFlagContext.Provider>;
};