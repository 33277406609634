import { CounterpartyWithStaffer, CpUser } from "@dbt/commons/types";
import { useUnmount } from "@dbt/commons/hooks";
import { getCe, getUser } from "api/client/user";
import produce from "immer";
import { useRouter } from "next/router";
import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { from, takeUntil } from "rxjs";
import { User } from "lib/session";
import { ApiRoutes, getCounterparty } from "api/client/counterparties";
import { mutate } from "swr";
import { applicationPaths } from "constants/paths";
import log from "@dbt-capital/log";
interface Auth {
  user?: CpUser;
  counterparty?: CounterpartyWithStaffer;
  sessionData?: User;
  login(): void;
  logout(): void;
}
export const apiCall = async (orgId?: string | null) => {
  const res = await getUser();
  if (!res) return {
    user: undefined
  };
  const user = await produce(res, async draft => {
    for (let i = 0; i < draft.representations.length; i++) {
      const representation = draft.representations[i];
      if (representation.stafferEmail) {
        try {
          const ceInfo = await getCe(representation.stafferEmail);
          draft.representations[i] = {
            ...representation,
            ...ceInfo
          };
        } catch (e: any) {
          // when we were testing DBT-1218, it is found out that some staffers don't exists in portal (why???)
          // So, if provided staffer email does not exist in the portal, we will ignore and let user proceed with login.
          // Otherwise, loading spinner will show forever and user will not be able to login or worse logout.
          // We believe the login should not be interrupted, if one company has a non-existence owner.
          if (e.statusCode !== 404) {
            throw e;
          }
        }
      }
    }
  });

  // Incase user has already selected "orgId" in session data, we check that organization still available to the user.
  // Because in some cases user won't have access to certain org that he previously had access.
  // (For example an admin set a particular user/orgId to "removed" status type at some point)
  const isSelectedOrgIdAvailable = user.representations.find(r => r.orgId === orgId);
  if (orgId && isSelectedOrgIdAvailable) {
    const counterparty = await getCounterparty(orgId);
    await mutate(ApiRoutes.getCounterparty(orgId), counterparty, {
      revalidate: false
    });
  }
  return {
    user
  };
};
type ApiResponse = Partial<Awaited<ReturnType<typeof apiCall>>>;
export const AuthContext = createContext<Auth>(({} as Auth));
export const AuthProvider = ({
  orgId,
  isAuthenticated,
  sessionData,
  noAuth,
  children
}: PropsWithChildren<{
  orgId?: string | null;
  isAuthenticated: boolean;
  sessionData: User | undefined;
  noAuth?: boolean;
}>) => {
  const router = useRouter();
  const unmount$ = useUnmount();
  const [res, setRes] = useState<ApiResponse>();
  const fetchUser = useCallback((login?: boolean) => {
    from(apiCall(orgId)).pipe(takeUntil(unmount$)).subscribe({
      next: res => {
        setRes(res);
        const forward = (router.query.forward as string);
        if (forward) {
          router.push(forward);
        } else {
          if (login) {
            router.push(applicationPaths.application);
          }
        }
      },
      error: error => {
        // Unable to fetch user
        logout();
        log.error(error);
      }
    });
  }, [unmount$, router]);
  useEffect(() => {
    if (!noAuth && !isAuthenticated && res?.user) {
      setRes(undefined);
      alert("Sessionen har upphört och du loggades ut");
    }
  }, [noAuth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated && !res?.user) {
      fetchUser();
    }
  }, [isAuthenticated, res?.user]);
  const login = useCallback(() => {
    fetchUser(true);
  }, [fetchUser]);
  const logout = useCallback(() => {
    from(fetch("/api/logout")).pipe(takeUntil(unmount$)).subscribe({
      next: () => {
        setRes(undefined);
      }
    });
  }, [unmount$]);
  const value = useMemo(() => ({
    ...res,
    login,
    logout,
    sessionData
  }), [res, login, logout, sessionData]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};