import { dbtTheme } from "../../../configs/dbtTheme";
export const AccountLoggedOut = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 19.6534V22H21V19.6534C21 17.8819 19.8348 16.3214 18.1364 15.8182L12 14L5.86365 15.8182C4.16517 16.3214 3 17.8819 3 19.6534ZM4 19.6534V21H20V19.6534C20 18.3248 19.1261 17.1544 17.8523 16.777L12 15.043L6.14774 16.777C4.87388 17.1544 4 18.3248 4 19.6534Z" fill={color} />
    </svg>;
};