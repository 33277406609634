import { createContext, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useDrawdowns } from "hooks/api";
import { ApiRoutes, createDrawdown } from "api/client/drawdown";
import { Drawdown, Agreement, DrawdownStatus } from "@dbt/commons/types";
import { mutate } from "swr";
import { sendSlackMessageAgreement } from "./api/client/slack";
import { useUser } from "./hooks/contexts";
interface DrawdownInput {
  requestedAmount: number;
  requestedLoanPeriod: number;
  requestedGracePeriod: number;
}
interface DrawdownInputState {
  requestedAmount: boolean;
  requestedLoanPeriod: boolean;
  requestedGracePeriod: boolean;
}
export interface DrawdownState {
  isDataLoading: boolean;
  agreementId: string;
  totalLoanAmount: number;
  interestRate: number;
  approvedLoanAmounts: number[];
  drawdownInput: DrawdownInput;
  drawdownInputState: DrawdownInputState;
  drawdowns: Drawdown[];
  approvedDrawdowns: Drawdown[];
  pendingDrawdown: Drawdown | undefined;
  postDrawdown(): void;
  handleChangeSlider(value: number | number[], property: string): void;
}
export const DrawdownContext = createContext<DrawdownState>(({} as DrawdownState));
export const DrawdownProvider = ({
  data = [],
  children
}: PropsWithChildren<{
  data: Agreement[];
}>) => {
  const agreement = data[0];
  const {
    currentRepresentation
  } = useUser();
  const {
    data: drawdowns = [],
    loading: isDrawdownsDataLoading
  } = useDrawdowns(agreement.agreementId);
  const isDataLoading = isDrawdownsDataLoading;
  const agreementId = agreement.agreementId;
  const interestRate = agreement.interest;
  const totalLoanAmount = agreement.amount / 1_000_000;
  const approvedLoanAmounts = drawdowns.filter(drawdown => drawdown.status === DrawdownStatus.APPROVED).map(drawdown => {
    return drawdown.approvedAmount ? drawdown.approvedAmount / 1_000_000 : 0;
  }, 0);
  const [drawdownInput, setDrawdownInput] = useState({
    requestedAmount: 2,
    requestedLoanPeriod: 1,
    requestedGracePeriod: 0
  });
  const [drawdownInputState, setDrawdownInputState] = useState<DrawdownInputState>({
    requestedAmount: false,
    requestedLoanPeriod: false,
    requestedGracePeriod: false
  });
  const handleChangeSlider = useCallback((value: number | number[], property: string) => {
    setDrawdownInput(state => ({
      ...state,
      [property]: value
    }));
    setDrawdownInputState(state => ({
      ...state,
      [property]: true
    }));
  }, []);
  const pendingDrawdown = useMemo(() => drawdowns.find(drawdown => drawdown.status === DrawdownStatus.PENDING), [drawdowns]);
  const approvedDrawdowns = useMemo(() => {
    return drawdowns.filter(drawdown => drawdown.approvedAmount);
  }, [drawdowns]);
  const postDrawdown = useCallback(() => {
    createDrawdown(agreementId, drawdownInput.requestedAmount * 1_000_000, drawdownInput.requestedLoanPeriod, drawdownInput.requestedGracePeriod).then(() => {
      sendSlackMessageAgreement({
        orgId: currentRepresentation.orgId,
        orgName: currentRepresentation.orgName ?? "",
        type: "drawdown",
        amount: drawdownInput.requestedAmount * 1_000_000,
        loanPeriod: drawdownInput.requestedLoanPeriod,
        gracePeriod: drawdownInput.requestedGracePeriod
      }).then(() => mutate(ApiRoutes.getDrawdown(agreementId)));
    });
  }, [agreementId, drawdownInput, currentRepresentation]);
  const value = useMemo(() => ({
    isDataLoading,
    agreementId,
    totalLoanAmount,
    interestRate,
    approvedLoanAmounts,
    drawdownInput,
    drawdownInputState,
    drawdowns,
    approvedDrawdowns,
    pendingDrawdown,
    postDrawdown,
    handleChangeSlider
  }), [isDataLoading, agreementId, totalLoanAmount, interestRate, approvedLoanAmounts, drawdownInput, drawdownInputState, drawdowns, approvedDrawdowns, pendingDrawdown, postDrawdown, handleChangeSlider]);
  return <DrawdownContext.Provider value={value}>
      {children}
    </DrawdownContext.Provider>;
};