import React from "react";
export const triggerWithEnterOrSpaceKey = (callFnc?: (e?: any) => void) => {
  return (e: React.KeyboardEvent<any>) => {
    e.preventDefault();
    if (e.key === "Enter" || e.key === " ") {
      if (callFnc) {
        callFnc();
      }
    }
  };
};
export const navigateToAnchor = (anchorId: string) => {
  return () => window.location.href = `#${anchorId}`;
};