import { DocumentRepositoryItem, Job, JobSieData } from "@dbt/commons/types";
import { buildUrl, getOrThrowResponse, SIEMetadata } from "@dbt/commons/utils";
export const ApiRoutes = {
  readSieMetadata: (originalFileName: string) => buildUrl("/api/portal/sie-metadata", {
    originalFileName
  }),
  getJobs: (orgId: string, archiveFailedOnFetch?: boolean) => buildUrl(`/api/portal/jobs`, {
    orgId,
    archiveFailedOnFetch
  }),
  archiveJobs: (jobId: number) => buildUrl(`/api/portal/archive-job`, {
    jobId
  })
};
export async function readSieMetadata(file: File): Promise<SIEMetadata> {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const response = await fetch(ApiRoutes.readSieMetadata(file.name), {
    method: "POST",
    body: formData
  });
  return getOrThrowResponse(response);
}
export async function uploadSieFile({
  orgId,
  file,
  orgName,
  ceEmail,
  userName,
  originalFileName,
  documentsUrl
}: {
  orgId: string;
  file: File;
  orgName: string | null;
  ceEmail: string;
  userName: string;
  originalFileName: string;
  documentsUrl: string;
}): Promise<DocumentRepositoryItem> {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const response = await fetch(buildUrl("/api/portal/upload-sie", {
    originalFileName,
    orgName,
    ceEmail,
    userName,
    documentsUrl,
    orgId
  }), {
    method: "POST",
    body: formData
  });
  return getOrThrowResponse(response);
}
export async function getJobs(orgId: string, archiveFailedOnFetch?: boolean): Promise<Job<JobSieData>[]> {
  const response = await fetch(ApiRoutes.getJobs(orgId, archiveFailedOnFetch));
  return getOrThrowResponse(response);
}
export async function archiveJob(jobId: number): Promise<Job<JobSieData>[]> {
  const response = await fetch(ApiRoutes.archiveJobs(jobId), {
    method: "PATCH",
    body: JSON.stringify({
      isArchived: true
    })
  });
  return getOrThrowResponse(response);
}