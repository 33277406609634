import { dbtTheme } from "../../../configs/dbtTheme";
export const LoanAlternative = ({
  color = dbtTheme.colors.grey_60
}: {
  color?: string;
}) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3ZM9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 13C3.79086 13 2 14.7909 2 17C2 19.2091 3.79086 21 6 21C8.20914 21 10 19.2091 10 17C10 14.7909 8.20914 13 6 13ZM3 17C3 18.6569 4.34315 20 6 20C7.65685 20 9 18.6569 9 17C9 15.3431 7.65685 14 6 14C4.34315 14 3 15.3431 3 17Z" fill={color} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 17C14 14.7909 15.7909 13 18 13C20.2091 13 22 14.7909 22 17C22 19.2091 20.2091 21 18 21C15.7909 21 14 19.2091 14 17ZM18 20C16.3431 20 15 18.6569 15 17C15 15.3431 16.3431 14 18 14C19.6569 14 21 15.3431 21 17C21 18.6569 19.6569 20 18 20Z" fill={color} />
    </svg>;
};