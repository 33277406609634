import _styled from "@emotion/styled";
import { dbtTheme } from "../../configs/dbtTheme";
import { Link } from "../icons/24px";
import { Text } from "../text";
const ConnectedBoxContainer = _styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px 4px 8px;
  gap: 4px;
  background: ${dbtTheme.colors.status.success_10};
  border-radius: 4px;
`;
export const ConnectedBox = ({
  label,
  ...rest
}: {
  label: string;
  "data-testid"?: string;
}) => {
  return <ConnectedBoxContainer {...rest}>
      <Link color={dbtTheme.colors.status.success_100} />
      <Text color={dbtTheme.colors.status.success_100}>{label}</Text>
    </ConnectedBoxContainer>;
};