import { UAParser } from "ua-parser-js";

// NOTE : Just keep in mind that parsing data from "User Agent" string is not always reliable.
//        Because device/user/browser can manipulate them and even same category of browsers/devices with different versions
//        can have different values when parsed for details. For ex. IPad Air seem to parsed as tablet and IPad pro as desktop.

const parseUserAgent = (userAgent: string) => {
  if (!userAgent) {
    return;
  }
  const UserAgentInstance = new UAParser(userAgent);
  return {
    browser: UserAgentInstance.getBrowser(),
    cpu: UserAgentInstance.getCPU(),
    device: UserAgentInstance.getDevice(),
    engine: UserAgentInstance.getEngine(),
    os: UserAgentInstance.getOS(),
    ua: UserAgentInstance.getUA()
  };
};
export const useDeviceData = function () {
  if (!global.navigator) {
    return;
  }
  return parseUserAgent(global.navigator.userAgent);
};