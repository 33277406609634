export const applicationPaths = {
  application: "/application",
  home: "/",
  drawdown: "/drawdown",
  loanApplication: "/loanapplication",
  loans: "/loans",
  reports: "/reports",
  login: "/login",
  settings: "/settings",
  bookKeeping: "/bookkeeping",
  documents: "/documents"
};
export const externalPaths = {
  dbt: "https://www.dbt.se",
  about: "https://www.dbt.se/foretagslan",
  contact: "https://www.dbt.se/kontakta-oss"
};