import { useEffect, useState } from "react";
import { Subject } from "rxjs";
export type UnmountHookReturnType = any;
export function useUnmount(): UnmountHookReturnType {
  const [unmount$] = useState<Subject<any>>(() => new Subject());
  useEffect(() => {
    return () => {
      unmount$.next(true);
      unmount$.complete();
    };
  }, [unmount$]);
  return unmount$;
}