import { CounterpartyWithStaffer, CpStaff, CpUser } from "@dbt/commons/types";
import { buildUrl, getOrThrowResponse } from "@dbt/commons/utils";
export async function getUser(): Promise<CpUser> {
  const response = await fetch(`/api/portal/user`);
  return getOrThrowResponse(response);
}
export async function setCompany(orgId: string): Promise<CounterpartyWithStaffer> {
  const response = await fetch("/api/set-company", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      orgId
    })
  });
  return getOrThrowResponse(response);
}
export async function getCe(email: string): Promise<CpStaff> {
  const response = await fetch(buildUrl("/api/portal/ce", {
    email
  }), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
  return getOrThrowResponse(response);
}
export async function refreshSession(): Promise<{
  expire: number;
}> {
  const response = await fetch("/api/refresh-session");
  return getOrThrowResponse(response);
}