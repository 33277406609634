import { CounterpartyWithStaffer, CpInvoices, CpLoansOverview, DocumentRepositoryItem, DocumentRepositorySlot, DwCustomerBudActs } from "@dbt/commons/types";
import { buildUrl, getOrThrowResponse } from "@dbt/commons/utils";
export const ApiRoutes = {
  getActuals: (fromDate?: string, toDate?: string) => buildUrl("/api/portal/counterparties/actuals", {
    fromDate,
    toDate
  }),
  getLoansOverview: `/api/portal/counterparties/loans-overview`,
  getInvoices: `/api/portal/counterparties/invoices`,
  getCounterparty: (orgId: string) => buildUrl("/api/portal/counterparties/counterparty", {
    orgId
  }),
  getFiles: `/api/portal/counterparties/files`
};
export async function getActuals(fromDate?: string, toDate?: string): Promise<DwCustomerBudActs> {
  const response = await fetch(ApiRoutes.getActuals(fromDate, toDate));
  return getOrThrowResponse(response);
}
export async function getLoansOverview(): Promise<CpLoansOverview> {
  const response = await fetch(ApiRoutes.getLoansOverview);
  return getOrThrowResponse(response);
}
export async function getInvoices(): Promise<CpInvoices> {
  const response = await fetch(ApiRoutes.getInvoices);
  return getOrThrowResponse(response);
}
export async function getCounterparty(orgId: string): Promise<CounterpartyWithStaffer> {
  const response = await fetch(ApiRoutes.getCounterparty(orgId));
  return getOrThrowResponse(response);
}
export async function getFiles(): Promise<DocumentRepositorySlot[]> {
  const response = await fetch(ApiRoutes.getFiles);
  return getOrThrowResponse(response);
}
export async function uploadFile({
  file,
  slotId,
  orgName,
  ceEmail,
  userName,
  originalFileName,
  documentsUrl
}: {
  file: File;
  slotId: string;
  orgName: string | null;
  ceEmail: string;
  userName: string;
  originalFileName: string;
  documentsUrl: string;
}): Promise<DocumentRepositoryItem> {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const response = await fetch(buildUrl("/api/portal/counterparties/upload-file", {
    slotId,
    orgName,
    ceEmail,
    userName,
    originalFileName,
    documentsUrl
  }), {
    method: "POST",
    body: formData
  });
  return getOrThrowResponse(response);
}
export async function deleteFile(slotId: string, documentId: string) {
  const response = await fetch(`api/portal/counterparties/delete-file?slotId=${slotId}&documentId=${documentId}`, {
    method: "DELETE"
  });
  return getOrThrowResponse(response, "text");
}
export async function getBlob(blobId: string): Promise<Blob> {
  const response = await fetch(`api/portal/counterparties/get-blob?blobId=${encodeURIComponent(blobId)}`);
  return getOrThrowResponse(response, "blob");
}