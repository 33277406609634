import { DwCustomerActuals, DwCustomerBudActs } from "../types";
import { formatPercent } from "./formatting";
export interface ColDef {
  label: string;
  year: number;
  month: number;
}
export interface RowDef {
  label: string;
  style: string;
  indexName?: string;
}
export enum FinLine {
  // period
  NetIncome,
  OtherInc,
  COGS,
  GrossResult,
  // sum
  OtherExtCosts,
  PersCosts,
  EBITDA,
  // sum
  Depreciation,
  EBIT,
  // sum
  DBTInterest,
  FinancialNet,
  EBT,
  // sum
  Taxes,
  Earnings,
  // sum
  NOI,
  Cash,
  Equity,
  Receivables,
  StDebt,
  LtDebt,
  ShortTermInvestments,
  TaxesVat,
  UntaxedReserves,
  // closing
  NetIncomeClosing,
  OtherIncClosing,
  COGSClosing,
  GrossResultClosing,
  // sum
  OtherExtCostsClosing,
  PersCostsClosing,
  EBITDAClosing,
  // sum
  DepreciationClosing,
  EBITClosing,
  // sum
  FinancialNetClosing,
  EBTClosing,
  // sum
  TaxesClosing,
  EarningsClosing,
  // sum
  NOIClosing,
  CashClosing,
  EquityClosing,
  ReceivablesClosing,
  StDebtClosing,
  LtDebtClosing,
  ShortTermInvestmentsClosing,
  TaxesVatClosing,
  UntaxedReservesClosing,
  // others
  DBTAmort,
  DBTLoan,
  LineCount,
}
export type FinValue = number;
export interface FinDataSet {
  months: ColDef[];
  budgetData: FinValue[][];
  actualData: FinValue[][];
}

/// returns the sum of the provided values, or NaN if there is no non-null value
export function add(...addends: Array<number | null>): number {
  const r = addends.reduce((r, a) => a === null || isNaN(a) ? r : r === null ? a : r + a, null);
  return r === null ? NaN : r;
}

/// returns the result of subtracting the provided subtrahends from the minuend, or NaN if the minuend is null
export function sub(minuend: number | null, ...subtrahends: Array<number | null>): number {
  if (minuend === null) return NaN;
  return subtrahends.reduce((r: number, s) => s === null || isNaN(s) ? r : r - s, minuend);
}
export function transformBudActData(budActsData: DwCustomerBudActs | undefined, budVsAct?: boolean): FinDataSet | undefined {
  const taxRate = 0.206; // 20.6 %

  if (!budActsData) return undefined;
  for (const key in budActsData) {
    const prop: keyof DwCustomerBudActs = (key as keyof DwCustomerBudActs);
    budActsData[prop].sort((a: {
      yearMonth: string;
    }, b: {
      yearMonth: string;
    }) => a.yearMonth > b.yearMonth && 1 || -1);
  }
  const {
    budgets,
    actuals,
    initialSchedule,
    paymentActuals,
    outPrincActuals
  } = budActsData;
  const months = new Array<ColDef>();
  const startDates: string[] = [budgets[0]?.yearMonth, actuals[0]?.yearMonth, initialSchedule[0]?.yearMonth, paymentActuals[0]?.yearMonth, outPrincActuals[0]?.yearMonth];
  const firstDate = new Date(startDates.reduce((p, s) => s && s < p ? s : p, "9999-12-31"));
  const endDates: string[] = [budgets[budgets.length - 1]?.yearMonth, actuals[actuals.length - 1]?.yearMonth, initialSchedule[initialSchedule.length - 1]?.yearMonth, paymentActuals[paymentActuals.length - 1]?.yearMonth, outPrincActuals[outPrincActuals.length - 1]?.yearMonth];
  const [lastYear] = endDates.reduce((p, s) => s && s < p ? s : p, "9998-12-31").split("-").map(str => parseInt(str));
  let year = firstDate.getFullYear();
  let month = firstDate.getMonth() + 1;
  let bI = 0;
  let aI = 0;
  let iI = 0;
  let pI = 0;
  let oI = 0;
  const budgetData = new Array<FinValue[]>();
  const actualData = new Array<FinValue[]>();
  for (let colIx = 0;; colIx++) {
    // Note - the month traversal logic below will fail for a row set that has multiple rows for the same month.

    const columnLabel = `${year.toString()}${month < 10 ? "-0" : "-"}${month.toString()}`;
    let monthDataAvailable = false;
    budgetData[colIx] = new Array<FinValue>(FinLine.LineCount).fill(NaN);
    actualData[colIx] = new Array<FinValue>(FinLine.LineCount).fill(NaN);
    if (iI < initialSchedule.length && initialSchedule[iI].yearMonth.startsWith(columnLabel)) {
      budgetData[colIx][FinLine.DBTInterest] = -(initialSchedule[iI].dueInterest ?? NaN);
      budgetData[colIx][FinLine.DBTAmort] = -(initialSchedule[iI].duePrincipal ?? NaN);
      budgetData[colIx][FinLine.DBTLoan] = initialSchedule[iI].outstandingAmount;
      iI++;
      monthDataAvailable = true;
    }
    if (pI < paymentActuals.length && paymentActuals[pI].yearMonth.startsWith(columnLabel)) {
      actualData[colIx][FinLine.DBTInterest] = -(paymentActuals[pI].paidInterest ?? NaN);
      actualData[colIx][FinLine.DBTAmort] = -(paymentActuals[pI].paidPrincipal ?? NaN);
      pI++;
      monthDataAvailable = true;
    }
    if (oI < outPrincActuals.length && outPrincActuals[oI].yearMonth.startsWith(columnLabel)) {
      actualData[colIx][FinLine.DBTLoan] = outPrincActuals[oI].outstandingAmount;
      oI++;
      monthDataAvailable = true;
    }
    if (bI < budgets.length && budgets[bI].yearMonth.startsWith(columnLabel)) {
      // Period Budget
      budgetData[colIx][FinLine.NetIncome] = budgets[bI].netIncome;
      budgetData[colIx][FinLine.OtherInc] = budgets[bI].otherIncome;
      budgetData[colIx][FinLine.COGS] = budgets[bI].cogs;
      budgetData[colIx][FinLine.OtherExtCosts] = budgets[bI].otherExtCosts;
      budgetData[colIx][FinLine.PersCosts] = budgets[bI].personnelCosts;
      budgetData[colIx][FinLine.Depreciation] = budgets[bI].depreciation;
      budgetData[colIx][FinLine.FinancialNet] = sub(budgets[bI].financialNet, budgetData[colIx][FinLine.DBTInterest]);
      budgetData[colIx][FinLine.Taxes] = budgets[bI].taxes;
      budgetData[colIx][FinLine.GrossResult] = add(budgetData[colIx][FinLine.NetIncome], budgetData[colIx][FinLine.OtherInc], budgetData[colIx][FinLine.COGS]);
      budgetData[colIx][FinLine.EBITDA] = add(budgetData[colIx][FinLine.GrossResult], budgetData[colIx][FinLine.OtherExtCosts], budgetData[colIx][FinLine.PersCosts]);
      budgetData[colIx][FinLine.EBIT] = add(budgetData[colIx][FinLine.EBITDA], budgetData[colIx][FinLine.Depreciation]);
      budgetData[colIx][FinLine.EBT] = add(budgetData[colIx][FinLine.EBIT], budgetData[colIx][FinLine.DBTInterest], budgetData[colIx][FinLine.FinancialNet]);
      budgetData[colIx][FinLine.Earnings] = add(budgetData[colIx][FinLine.EBT], budgetData[colIx][FinLine.Taxes]);
      budgetData[colIx][FinLine.NOI] = sub(budgetData[colIx][FinLine.Earnings], budgetData[colIx][FinLine.Depreciation], budgetData[colIx][FinLine.DBTInterest], budgetData[colIx][FinLine.FinancialNet]);
      budgetData[colIx][FinLine.Cash] = budgets[bI].cash;

      // Closing Budget
      budgetData[colIx][FinLine.NetIncomeClosing] = budgets[bI].netIncomeAccumulated;
      budgetData[colIx][FinLine.OtherIncClosing] = budgets[bI].otherIncomeAccumulated;
      budgetData[colIx][FinLine.COGSClosing] = budgets[bI].cogsAccumulated;
      budgetData[colIx][FinLine.OtherExtCostsClosing] = budgets[bI].otherExtCostsAccumulated;
      budgetData[colIx][FinLine.PersCostsClosing] = budgets[bI].personnelCostsAccumulated;
      budgetData[colIx][FinLine.DepreciationClosing] = budgets[bI].depreciationAccumulated;
      budgetData[colIx][FinLine.FinancialNetClosing] = budgets[bI].financialNetAccumulated;
      budgetData[colIx][FinLine.TaxesClosing] = budgets[bI].taxesAccumulated;
      budgetData[colIx][FinLine.GrossResultClosing] = add(budgetData[colIx][FinLine.NetIncomeClosing], budgetData[colIx][FinLine.OtherIncClosing], budgetData[colIx][FinLine.COGSClosing]);
      budgetData[colIx][FinLine.EBITDAClosing] = add(budgetData[colIx][FinLine.GrossResultClosing], budgetData[colIx][FinLine.OtherExtCostsClosing], budgetData[colIx][FinLine.PersCostsClosing]);
      budgetData[colIx][FinLine.EBITClosing] = add(budgetData[colIx][FinLine.EBITDAClosing], budgetData[colIx][FinLine.DepreciationClosing]);
      budgetData[colIx][FinLine.EBTClosing] = add(budgetData[colIx][FinLine.EBITClosing], budgetData[colIx][FinLine.FinancialNetClosing]);
      budgetData[colIx][FinLine.EarningsClosing] = add(budgetData[colIx][FinLine.EBTClosing], budgetData[colIx][FinLine.TaxesClosing]);
      budgetData[colIx][FinLine.NOIClosing] = sub(budgetData[colIx][FinLine.EarningsClosing], budgetData[colIx][FinLine.DepreciationClosing], budgetData[colIx][FinLine.FinancialNetClosing]);
      budgetData[colIx][FinLine.CashClosing] = budgets[bI].cashAccumulated;
      bI++;
      monthDataAvailable = true;
    }
    if (aI < actuals.length && actuals[aI].yearMonth.startsWith(columnLabel)) {
      // Period Actuals
      actualData[colIx][FinLine.NetIncome] = -actuals[aI].netIncome.period;
      actualData[colIx][FinLine.OtherInc] = -actuals[aI].otherIncome.period;
      actualData[colIx][FinLine.COGS] = -actuals[aI].cogs.period;
      actualData[colIx][FinLine.OtherExtCosts] = -actuals[aI].otherExtCosts.period;
      actualData[colIx][FinLine.PersCosts] = -actuals[aI].personnelCosts.period;
      actualData[colIx][FinLine.Depreciation] = -actuals[aI].depreciation.period;
      actualData[colIx][FinLine.FinancialNet] = sub(-(actuals[aI].financialNet.period ?? NaN), actualData[colIx][FinLine.DBTInterest]);
      actualData[colIx][FinLine.GrossResult] = add(actualData[colIx][FinLine.NetIncome], actualData[colIx][FinLine.OtherInc], actualData[colIx][FinLine.COGS]);
      actualData[colIx][FinLine.EBITDA] = add(actualData[colIx][FinLine.GrossResult], actualData[colIx][FinLine.OtherExtCosts], actualData[colIx][FinLine.PersCosts]);
      actualData[colIx][FinLine.EBIT] = add(actualData[colIx][FinLine.EBITDA], actualData[colIx][FinLine.Depreciation]);
      actualData[colIx][FinLine.EBT] = add(actualData[colIx][FinLine.EBIT], actualData[colIx][FinLine.DBTInterest], actualData[colIx][FinLine.FinancialNet]);
      actualData[colIx][FinLine.Taxes] = actualData[colIx][FinLine.EBT] !== null ? -Math.max(actualData[colIx][FinLine.EBT], 0) * taxRate : NaN;
      actualData[colIx][FinLine.Earnings] = add(actualData[colIx][FinLine.EBT], actualData[colIx][FinLine.Taxes]);
      actualData[colIx][FinLine.NOI] = sub(actualData[colIx][FinLine.Earnings], actualData[colIx][FinLine.Depreciation], actualData[colIx][FinLine.DBTInterest], actualData[colIx][FinLine.FinancialNet]);
      actualData[colIx][FinLine.Cash] = actuals[aI].cash.period;
      actualData[colIx][FinLine.Equity] = actuals[aI].equity.period;
      actualData[colIx][FinLine.Receivables] = actuals[aI].receivables.period;
      actualData[colIx][FinLine.StDebt] = add(actuals[aI].stDebt.period, actuals[aI].taxesVat.period, actuals[aI].prepaidIncome.period, actuals[aI].untaxedReserves.period);
      actualData[colIx][FinLine.LtDebt] = actuals[aI].ltDebt.period;
      actualData[colIx][FinLine.ShortTermInvestments] = actuals[aI].stAssetsPrepaid.period;
      actualData[colIx][FinLine.TaxesVat] = actuals[aI].taxesVat.period;
      actualData[colIx][FinLine.UntaxedReserves] = actuals[aI].untaxedReserves.period;

      // Closing Actuals
      actualData[colIx][FinLine.NetIncomeClosing] = -actuals[aI].netIncome.closing;
      actualData[colIx][FinLine.OtherIncClosing] = -actuals[aI].otherIncome.closing;
      actualData[colIx][FinLine.COGSClosing] = -actuals[aI].cogs.closing;
      actualData[colIx][FinLine.OtherExtCostsClosing] = -actuals[aI].otherExtCosts.closing;
      actualData[colIx][FinLine.PersCostsClosing] = -actuals[aI].personnelCosts.closing;
      actualData[colIx][FinLine.DepreciationClosing] = -actuals[aI].depreciation.closing;
      actualData[colIx][FinLine.FinancialNetClosing] = -actuals[aI].financialNet.closing;
      actualData[colIx][FinLine.GrossResultClosing] = add(actualData[colIx][FinLine.NetIncomeClosing], actualData[colIx][FinLine.OtherIncClosing], actualData[colIx][FinLine.COGSClosing]);
      actualData[colIx][FinLine.EBITDAClosing] = add(actualData[colIx][FinLine.GrossResultClosing], actualData[colIx][FinLine.OtherExtCostsClosing], actualData[colIx][FinLine.PersCostsClosing]);
      actualData[colIx][FinLine.EBITClosing] = add(actualData[colIx][FinLine.EBITDAClosing], actualData[colIx][FinLine.DepreciationClosing]);
      actualData[colIx][FinLine.EBTClosing] = add(actualData[colIx][FinLine.EBITClosing], actualData[colIx][FinLine.FinancialNetClosing]);
      actualData[colIx][FinLine.TaxesClosing] = actualData[colIx][FinLine.EBTClosing] !== null ? -Math.max(actualData[colIx][FinLine.EBTClosing], 0) * taxRate : NaN;
      actualData[colIx][FinLine.EarningsClosing] = add(actualData[colIx][FinLine.EBTClosing], actualData[colIx][FinLine.TaxesClosing]);
      actualData[colIx][FinLine.NOIClosing] = sub(actualData[colIx][FinLine.EarningsClosing], actualData[colIx][FinLine.DepreciationClosing], actualData[colIx][FinLine.FinancialNetClosing]);
      actualData[colIx][FinLine.EquityClosing] = actuals[aI].equity.closing;
      actualData[colIx][FinLine.CashClosing] = actuals[aI].cash.closing;
      actualData[colIx][FinLine.ReceivablesClosing] = actuals[aI].receivables.closing;
      actualData[colIx][FinLine.StDebtClosing] = add(actuals[aI].stDebt.closing, actuals[aI].taxesVat.closing, actuals[aI].prepaidIncome.closing, actuals[aI].untaxedReserves.closing);
      actualData[colIx][FinLine.LtDebtClosing] = actuals[aI].ltDebt.closing;
      actualData[colIx][FinLine.ShortTermInvestmentsClosing] = actuals[aI].stAssetsPrepaid.closing;
      actualData[colIx][FinLine.TaxesVatClosing] = actuals[aI].taxesVat.closing;
      actualData[colIx][FinLine.UntaxedReservesClosing] = actuals[aI].untaxedReserves.closing;
      aI++;
      monthDataAvailable = true;
    }
    // Continue loop if no data for this month is available, but more data in later months/years is available
    if (!monthDataAvailable && year <= lastYear) {
      monthDataAvailable = true;
    }
    if (monthDataAvailable) {
      months[colIx] = {
        label: columnLabel,
        year,
        month: month - 1
      };
    } else {
      // year-to-date column displays the sum of the up to 12 last months of the shown periods,
      // including the ones for which there is both budget and actuals data, and the loan is active.
      budgetData[colIx].fill(0);
      actualData[colIx].fill(0);
      const sliceStart = Math.max(0, colIx - 12);
      const budgetSlice = budgetData.slice(sliceStart, colIx);
      const actualSlice = actualData.slice(sliceStart, colIx);
      let firstM = NaN;
      let lastM = NaN;
      for (let m = 0; m < actualSlice.length; m++) {
        if (actualSlice[m][FinLine.DBTLoan] && actualSlice[m][FinLine.NOI] && budgetSlice[m][FinLine.NOI]) {
          // if loan is active and there is actuals and budget data
          if (isNaN(firstM)) firstM = m;
          lastM = m;
          for (let r = 0; r < FinLine.LineCount; r++) {
            if (r === FinLine.CashClosing || r === FinLine.DBTLoan) {
              budgetData[colIx][r] = NaN;
              actualData[colIx][r] = NaN;
              continue;
            }
            budgetData[colIx][r] += isNaN(budgetSlice[m][r]) ? 0 : budgetSlice[m][r];
            actualData[colIx][r] += isNaN(actualSlice[m][r]) ? 0 : actualSlice[m][r];
          }
        }
      }
      if (isNaN(firstM)) months[colIx] = {
        label: "\u{3A3} n/a",
        year: NaN,
        month: NaN
      };else months[colIx] = {
        label: `\u{3A3} ${months[sliceStart + firstM]?.label.substring(2)} \u{2014} ${months[sliceStart + lastM]?.label.substring(2)}`,
        year: 0,
        month: 0
      };
      break;
    }
    month++;
    if (month > 12) {
      year++;
      month = 1;
    }
  }

  // Remove created sum element from array if not for Budget Vs. Actuals Tab, (not used in accelerator dasboard)
  if (!budVsAct) {
    months.pop();
    budgetData.pop();
    actualData.pop();
  }
  return {
    months,
    budgetData,
    actualData
  };
}

// Assigns actuals to budAct data object if only actuals fetched
export const createBudAct = (actuals: DwCustomerActuals[]): DwCustomerBudActs | undefined => {
  return actuals.length === 0 ? undefined : {
    actuals,
    budgets: [],
    paymentActuals: [],
    initialSchedule: [],
    outPrincActuals: []
  };
};
export interface YearSplitData {
  currentYear: FinDataSet;
  previousYear: FinDataSet;
  twoYear: FinDataSet;
}

/* check if Jan or Feb of current year has data, otherwise use previous year. */
export const validateFilterPeriod = (actuals: FinDataSet | undefined | null) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentPeriod = [currentYear, currentYear - 1, currentYear - 2];
  const previousPeriod = [currentYear - 1, currentYear - 2, currentYear - 3];
  if (!actuals || currentMonth > 1) return currentPeriod;
  const indexJan = actuals.months.findIndex(month => month.month === 0 && month.year === currentYear);
  const indexFeb = actuals.months.findIndex(month => month.month === 1 && month.year === currentYear);
  if (indexJan !== -1 && !isNaN(actuals.actualData[indexJan][FinLine.NetIncome]) || indexFeb !== -1 && !isNaN(actuals.actualData[indexFeb][FinLine.NetIncome])) {
    return currentPeriod;
  } else {
    return previousPeriod;
  }
};

// splits budAct data in different years and filter by months
export const splitByYearAndMonth = (data: FinDataSet | undefined | null, months: number[], yearsParam?: number[]): YearSplitData => {
  const years = yearsParam || validateFilterPeriod(data);
  years.sort((a, b) => b - a);
  months.sort((a, b) => a - b);
  const filteredData = ({
    currentYear: {
      actualData: [],
      budgetData: [],
      months: []
    },
    previousYear: {
      actualData: [],
      budgetData: [],
      months: []
    },
    twoYear: {
      actualData: [],
      budgetData: [],
      months: []
    }
  } as YearSplitData);
  if (data) {
    data.months.forEach((month, index) => {
      if (month.year === years[0] && months.includes(month.month)) {
        filteredData.currentYear.actualData.push(data.actualData[index]);
        filteredData.currentYear.budgetData.push(data.budgetData[index]);
        filteredData.currentYear.months.push(data.months[index]);
      }
      if (month.year === years[1] && months.includes(month.month)) {
        filteredData.previousYear.actualData.push(data.actualData[index]);
        filteredData.previousYear.budgetData.push(data.budgetData[index]);
        filteredData.previousYear.months.push(data.months[index]);
      }
      if (month.year === years[2] && months.includes(month.month)) {
        filteredData.twoYear.actualData.push(data.actualData[index]);
        filteredData.twoYear.budgetData.push(data.budgetData[index]);
        filteredData.twoYear.months.push(data.months[index]);
      }
    });
  }
  return filteredData;
};
export interface MatchingData {
  currentYear: FinDataSet;
  previousYear: FinDataSet;
}

// filter current and previous year data by only matching months
export const filterByMatchingMonths = (data: YearSplitData) => {
  const filteredData = ({
    currentYear: {
      actualData: [],
      budgetData: [],
      months: []
    },
    previousYear: {
      actualData: [],
      budgetData: [],
      months: []
    }
  } as MatchingData);

  // loop through all months
  // check if month exists in all years
  // check if NetIncome in actuals exists
  // if true, only push matching months to a new array
  for (let i = 0; i < 12; i++) {
    const checkYear1 = data.currentYear.months.some(month => month.month === i);
    const checkYear2 = data.previousYear.months.some(month => month.month === i);
    if (checkYear1 && checkYear2) {
      const indexCurrent = data.currentYear.months.findIndex(month => month.month === i);
      const indexPrevious = data.previousYear.months.findIndex(month => month.month === i);
      if (!isNaN(data.currentYear.actualData[indexCurrent][FinLine.NetIncome]) && !isNaN(data.previousYear.actualData[indexPrevious][FinLine.NetIncome])) {
        filteredData.currentYear.actualData.push(data.currentYear.actualData[indexCurrent]);
        filteredData.currentYear.budgetData.push(data.currentYear.budgetData[indexCurrent]);
        filteredData.currentYear.months.push(data.currentYear.months[indexCurrent]);
        filteredData.previousYear.actualData.push(data.previousYear.actualData[indexPrevious]);
        filteredData.previousYear.budgetData.push(data.previousYear.budgetData[indexPrevious]);
        filteredData.previousYear.months.push(data.previousYear.months[indexPrevious]);
      }
    }
  }
  return filteredData;
};
export const filterByLastTwelveMonths = (data: FinDataSet, startYear: number, startMonth: number) => {
  const filteredData = ({
    actualData: ([] as FinValue[][]),
    budgetData: ([] as FinValue[][]),
    months: ([] as ColDef[])
  } as FinDataSet);
  if (data.actualData.length === 0 || data.budgetData.length === 0 || data.months.length === 0) {
    return filteredData;
  }

  // find latest available Data by NetIncome
  let monthIdx = data.months.findIndex(month => month.year === startYear && month.month === startMonth);
  if (monthIdx === -1) monthIdx = data.months.length - 1;
  while (isNaN(data.actualData[monthIdx][FinLine.NetIncome]) && monthIdx !== 0) {
    monthIdx--;
  }

  // In case 12 months of data not available, return available data.
  const numOfMonthsDataToBeReturned = monthIdx >= 11 ? 12 : monthIdx + 1;
  if (monthIdx === 0) {
    return filteredData;
  } else {
    for (let idx = monthIdx; idx > monthIdx - numOfMonthsDataToBeReturned; idx--) {
      filteredData.actualData.push(data.actualData[idx]);
      filteredData.budgetData.push(data.budgetData[idx]);
      filteredData.months.push(data.months[idx]);
    }
    filteredData.months.reverse();
    filteredData.actualData.reverse();
    filteredData.budgetData.reverse();
    return filteredData;
  }
};
export const sumValues = (array: FinValue[][]) => {
  return array.reduce(function (r, a) {
    a.forEach(function (b, i) {
      r[i] = (!isNaN(r[i]) ? r[i] : 0) + (!isNaN(b) ? b : 0);
    });
    return r;
  }, []);
};
export function calculateChangeInPercentage(current: number, previous: number): number | null {
  const result = (current - previous) / Math.abs(previous);
  return isFinite(result) ? result : null;
}
export function getArrowDirection(percentage: number | null): any {
  if (percentage === null) return "";
  if (percentage > 0) {
    return "up";
  } else if (percentage < 0) {
    return "down";
  } else {
    return "equal";
  }
}
export function getChangeProps(current: number, previous: number): {
  value: string | null;
  direction: any;
  color: string;
} {
  const value = calculateChangeInPercentage(current, previous);
  return {
    value: formatPercent(value),
    direction: getArrowDirection(value),
    color: value && value > 0 ? "text-green-600" : value && value < 0 ? "text-red-600" : ""
  };
}

/**
 * @param limit - if percentage is higher than the limit, return 0 to avoid high percentage outcome
 */
function calcPercentageOfRevenue(revenue: number, compareValue: number, limit?: number) {
  const percentage = compareValue * 100 / revenue;
  if (limit && Math.abs(percentage) > limit) return 0;
  return percentage;
}
export function createWaterfallData(data: number[]) {
  const netIncome = data[FinLine.NetIncome];
  const otherIncome = data[FinLine.OtherInc];
  const persCosts = data[FinLine.PersCosts];
  const cogs = data[FinLine.COGS];
  const otherExtCosts = data[FinLine.OtherExtCosts];
  const ebitda = data[FinLine.EBITDA];
  const depreciation = data[FinLine.Depreciation];
  const interest = data[FinLine.DBTInterest];
  const financialNet = data[FinLine.FinancialNet];
  const ebt = data[FinLine.EBT];
  const revenue = netIncome + otherIncome;
  const otherCosts = cogs + otherExtCosts;
  const percRevenue = calcPercentageOfRevenue(revenue, revenue, 3000);
  const percPersonnel = calcPercentageOfRevenue(revenue, -persCosts, 3000);
  const percOtherCosts = calcPercentageOfRevenue(revenue, -otherCosts, 3000);
  const percEbitda = calcPercentageOfRevenue(revenue, ebitda, 3000);
  const percDepreAmort = calcPercentageOfRevenue(revenue, -depreciation, 3000);
  const percInterest = calcPercentageOfRevenue(revenue, -(interest + financialNet), 3000);
  const perEbt = calcPercentageOfRevenue(revenue, ebt, 3000);

  /**
   * index 0 = bottom Value in chart
   * index 1 = top value in chart
   * index 2 = percentage
   */

  return [[percRevenue - percRevenue, percRevenue, percRevenue], [percRevenue - percPersonnel, percRevenue, percPersonnel], [percRevenue - percPersonnel - percOtherCosts, percRevenue - percPersonnel, percOtherCosts], [percRevenue - percPersonnel - percOtherCosts - percEbitda, percRevenue - percPersonnel - percOtherCosts, percEbitda], [percRevenue - percPersonnel - percOtherCosts - percDepreAmort, percRevenue - percPersonnel - percOtherCosts, percDepreAmort], [percRevenue - percPersonnel - percOtherCosts - percDepreAmort - percInterest, percRevenue - percPersonnel - percOtherCosts - percDepreAmort, percInterest], [percRevenue - percPersonnel - percOtherCosts - percDepreAmort - percInterest - perEbt, percRevenue - percPersonnel - percOtherCosts - percDepreAmort - percInterest, perEbt]];
}
export function isDataOutdated(data?: FinDataSet | null) {
  const today = new Date().getTime();
  const quarterYear = 1000 * 60 * 60 * 24 * 30 * 3;
  return data && data.months.length > 0 && today - new Date(data.months[data.months.length - 1].label).getTime() > quarterYear;
}